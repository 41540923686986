import React, { useState } from 'react';
import orderIcon from '../../assets/order-icon.svg';
import { Button } from '../ui/Button';
import checkmark from '../../assets/checkmark.svg';
import locationMarker from '../../assets/location-marker.svg';
import dividerInfo from '../../assets/divider-info.svg';
import notes from '../../assets/notes.svg';
import { useNavigate } from 'react-router-dom';
import CustomDashedLine from '../ui/DashedLine';

const OrderCardDriver = ({ order }) => {
  const [isShowNotes, setIsShowNotes] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: '0 0 auto',
        border: '1px solid #bbb',
        borderRadius: '5px',
        maxHeight: '90%',
        padding: '10px',
        width: '90%',
        backgroundColor: '#fff',
        overflowY: 'scroll',
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', marginBottom: '4px' }}>
            <img
              src={orderIcon}
              alt="order icon"
              style={{ height: '13px', marginRight: '10px' }}
            />
            <span
              style={{
                fontWeight: 'bold',
                height: '13px',
                textAlign: 'center',
                alignItems: 'center',
                lineHeight: '12px',
              }}
            >
              {order.id}
            </span>
          </div>
          <span
            style={{ fontWeight: 'bold', marginLeft: '22px', fontSize: '12px' }}
          >{`Truck# ${order.mainTruckNumber}`}</span>
          <span
            style={{
              marginLeft: '22px',
              fontSize: '12px',
              marginBottom: '10px',
            }}
          >{`ProNumber ${order.proNumber}`}</span>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <span
            style={{
              backgroundColor: '#ffc222',
              lineHeight: '14px',
              padding: '3px 7px',
              fontSize: '12px',
              fontWeight: 'bold',
              borderRadius: '15px',
            }}
          >
            Dispatched
          </span>
          <span
            style={{
              backgroundColor: '#00bf13',
              lineHeight: '14px',
              padding: '3px 7px',
              fontSize: '12px',
              fontWeight: 'bold',
              borderRadius: '15px',
              alignItems: 'center',
              color: 'white',
              display: order.ticketNumber !== '' ? '' : 'none',
              textAlign: 'center',
            }}
          >
            Ticket{' '}
            <img
              src={checkmark}
              alt="checkmark"
              style={{ display: 'inline' }}
            />
          </span>
        </div>
      </div>
      <CustomDashedLine width={8} space={8} height={1} />
      <div
        style={{
          display: 'flex',
          gap: '5px',
          flexWrap: 'nowrap',
          overflowX: 'auto',
          padding: '5px 0px',
          marginBottom: '10px',
          minHeight: '45px',
        }}
      >
        <div
          style={{
            backgroundColor: '#f4f4f4',
            padding: '5px 12px',
            borderRadius: '10px',
            width: 'auto',
            flex: '0 0 auto',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Order #</span>{' '}
          {order.orderNumber}
        </div>
        <div
          style={{
            backgroundColor: '#f4f4f4',
            padding: '5px 12px',
            borderRadius: '10px',
            width: 'auto',
            flex: '0 0 auto',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>PO #</span> {order.poNumber}
        </div>
        <div
          style={{
            backgroundColor: '#f4f4f4',
            padding: '5px 12px',
            borderRadius: '10px',
            width: 'auto',
            flex: '0 0 auto',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Dispatch #</span>{' '}
          {order.dispatchNumber}
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '4px' }}>
        <img
          src={locationMarker}
          alt="order icon"
          style={{ height: '15px', marginRight: '10px', marginBottom: '10px' }}
        />
        <span
          style={{
            fontWeight: 'bold',
            height: '13px',
            textAlign: 'center',
            alignItems: 'center',
            lineHeight: '12px',
          }}
        >
          DELIVERY INFORMATION
        </span>
      </div>
      <div
        style={{
          backgroundColor: '#f4f4f4',
          padding: '10px 17px',
          borderRadius: '10px',
          display: 'flex',
          alignItems: 'center',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '130px',
          }}
        >
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
              {order.pickupTime !== ':' ? order.pickupTime : '9:00'}
            </p>
            <p style={{ fontSize: '14px' }}>
              {order?.pickupDate?.slice(0, 10)?.slice(5, 10)}
            </p>
          </div>
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: 'bold', fontSize: '18px' }}>
              {order.dropoffTime !== ':' ? order.dropoffTime : '18:00'}
            </p>
            <p style={{ fontSize: '14px' }}>
              {order?.dropoffDate?.slice(0, 10)?.slice(5, 10)}
            </p>
          </div>
        </div>
        <img
          src={dividerInfo}
          alt="divider"
          style={{ height: '140px', marginRight: '10px' }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '130px',
          }}
        >
          <p style={{ fontSize: '14px' }}>{order.pickupLocation}</p>
          <p style={{ fontSize: '14px' }}>{order.dropoffLocation}</p>
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '10px' }}>
        <div
          style={{
            backgroundColor: '#f4f4f4',
            padding: '10px 12px',
            borderRadius: '10px',
          }}
        >
          <span style={{ fontWeight: 'bold' }}>Material:</span> {order.material}
        </div>
      </div>
      <div style={{ display: 'flex', marginBottom: '4px' }}>
        <img
          src={notes}
          alt="order icon"
          style={{ height: '15px', marginRight: '10px', marginBottom: '10px' }}
        />
        <span
          style={{
            fontWeight: 'bold',
            height: '13px',
            textAlign: 'center',
            alignItems: 'center',
            lineHeight: '12px',
          }}
        >
          NOTES
        </span>
      </div>
      <div
        style={{
          backgroundColor: '#f4f4f4',
          padding: '10px 12px',
          borderRadius: '10px',
          marginBottom: '10px',
        }}
      >
        <p>
          {order.notes.length > 30 ? order?.notes?.slice(0, 30) : order.notes}
          <span
            style={{
              display: isShowNotes || order.notes.length < 31 ? 'none' : '',
            }}
          >
            ...
          </span>
          <span style={{ display: isShowNotes ? '' : 'none' }}>
            {order?.notes?.slice(30, -1)}
          </span>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <span
            style={{
              color: 'blue',
              textDecoration: 'underline',
              cursor: 'pointer',
              display: order.notes > 30 ? '' : 'none',
            }}
            onClick={() => setIsShowNotes(!isShowNotes)}
          >
            {isShowNotes ? 'Read less' : 'Read more'}
          </span>
        </p>
      </div>
      <Button
        className="w-full"
        onClick={() =>
          navigate(`/dashboard-drivers/upload-ticket?id=${order._id}`)
        }
      >
        UPLOAD TICKET +
      </Button>
    </div>
  );
};

export default OrderCardDriver;
