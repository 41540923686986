import React from 'react'

const ErrorMessage = ({ message, className: classes }) => {
  return (
    <p className={`${classes} text-red-700 bg-red-100 border border-red-400 px-4 py-2 rounded`}>
      { message }
    </p>
  )
}

export default ErrorMessage;