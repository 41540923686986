import Cookies from 'js-cookie';
import useApi from '@/hooks/useApi';
import { useEffect, useState } from 'react';

export const useAuthProvider = () => {
  const [accessToken, setAccessToken] = useState(Cookies.get('accessToken'));

  const { error, makeApiCall } = useApi();

  const [message, setMessage] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(Boolean(accessToken));

  // Effect to handle general errors
  useEffect(() => {
    if (error) {
      setMessage({ text: error, type: 'error' });
    }
  }, [error, setMessage]);

  const login = async (username, password) => {
    const { data } = await makeApiCall({
      url: '/auth',
      method: 'POST',
      data: { username, password },
    });

    const { agentId, accessToken, refreshToken, error } = data;

    if (accessToken && refreshToken) {
      // Update the access token in the state and cookies
      setAccessToken(accessToken);
      Cookies.set('accessToken', accessToken);
      Cookies.set('refreshToken', refreshToken);
      Cookies.set('agentId', agentId);
      setIsAuthenticated(true);
    } else if (error) {
      setMessage({ text: data.error.message, type: 'error' });
    }
  };

  const logout = () => {
    // Implement your logout logic
    Cookies.remove('refreshToken');
    Cookies.remove('accessToken');
    setIsAuthenticated(false);
  };

  return { isAuthenticated, login, logout, message, setMessage };
};
