import * as React from 'react';
import { cn } from '../../lib/utils';
import { Eye, EyeOff } from 'lucide-react';

const Input = React.forwardRef(
  (
    { className, type, icon, connectedRight, suffix, inputMode, ...props },
    ref
  ) => {
    const [showPassword, setShowPassword] = React.useState(false);
    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
    };

    return (
      <div className="relative flex text-sm">
        {(icon || type === 'money') && (
          <div className="absolute inset-y-0 py-2.5 left-0 items-center pl-3 pointer-events-none">
            {icon ? icon : '$'}
          </div>
        )}
        <input
          disabled={props.disabled}
          type={
            type === 'password' ? (showPassword ? 'text' : 'password') : type
          }
          inputMode={inputMode || (type === 'money' ? 'numeric' : 'text')}
          className={cn(
            'flex h-10 w-full border border-primary bg-background px-3 py-2 ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50',
            (icon || type === 'money') && 'pl-10',
            type === 'money' && 'text-right',
            connectedRight ? 'rounded-l-md border-r-0' : 'rounded-md',
            className
          )}
          ref={ref}
          {...props}
        />
        {(type === 'password' || suffix) && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-2">
            {type === 'password' &&
              (showPassword ? (
                <EyeOff
                  color="#A8A7A7"
                  width={20}
                  onClick={togglePasswordVisibility}
                />
              ) : (
                <Eye
                  color="#A8A7A7"
                  width={20}
                  onClick={togglePasswordVisibility}
                />
              ))}
            {suffix && suffix}
          </div>
        )}
        {React.isValidElement(connectedRight) && (
          <div className="flex items-center">
            {React.cloneElement(connectedRight, {
              className: cn(connectedRight.props.className, 'rounded-l-none'),
            })}
          </div>
        )}
      </div>
    );
  }
);

Input.displayName = 'Input';

export { Input };
