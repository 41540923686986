'use client';

import React, { useState } from 'react';
import { Button } from '@/components/ui/Button';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { Checkbox } from './checkbox';
import { customFilter } from '@/lib/utils';
import { ScrollArea } from './scroll-area';

export function Combobox({
  options,
  setFilterValue,
  propertyName,
  label,
  defaultOptions,
}) {
  const [open, setOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState(
    defaultOptions ? defaultOptions : []
  );

  const handleOptionToggle = (value) => {
    const isAllSelected = selectedValues.length === options.length;
    let newValues;

    if (value === 'all') {
      setSelectedValues(isAllSelected ? [] : options);
    } else {
      newValues = selectedValues.includes(value)
        ? selectedValues.filter((selectedValue) => selectedValue !== value)
        : [...selectedValues, value];

      setSelectedValues(newValues);
    }
    setFilterValue(
      propertyName,
      value === 'all' ? (isAllSelected ? [] : options) : newValues
    );
  };

  const isAllOptionSelected = selectedValues.length === options.length;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          size="filter"
          variant="filter"
          role="combobox"
          aria-expanded={open}
          className={`overflow-hidden ${
            selectedValues.length === 0 && 'text-[#D3D3D3]'
          }`}
        >
          {selectedValues.length > 0
            ? selectedValues.map((value) => value).join(', ')
            : label
            ? label
            : 'Select option...'}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0">
        <Command filter={customFilter}>
          <CommandInput placeholder={`Search ${label}...`} />
          <CommandEmpty>No options found.</CommandEmpty>
          <ScrollArea className="h-[250px]">
            <CommandGroup>
              <CommandItem key={`all-${propertyName}`}>
                <Checkbox
                  checked={isAllOptionSelected}
                  onClick={() => handleOptionToggle('all')}
                />
                <span className="ml-2">Select All</span>
              </CommandItem>
              {options.map(
                (item, idx) =>
                  item && (
                    <CommandItem key={`${propertyName}_${idx}`}>
                      <Checkbox
                        checked={selectedValues.includes(item)}
                        onClick={() => handleOptionToggle(item)}
                      />
                      <span className="ml-2">{item}</span>
                    </CommandItem>
                  )
              )}
            </CommandGroup>
          </ScrollArea>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
