import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

const ProtectedRoute = ({ route }) => {
  const navigate = useNavigate();
  const isAuthenticated = !!Cookies.get('accessToken'); // Check if the access token is present

  if (!isAuthenticated) {
    navigate(route);
  }
  return <Outlet />;
};

export { ProtectedRoute };
