const DeleteIcon = ({ color, ...props }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.18054 17.5V4.58331H3.33331V3.19444H7.33331V2.5H12.6666V3.19444H16.6666V4.58331H15.8194V17.5H4.18054ZM5.56942 16.1111H14.4305V4.58331H5.56942V16.1111ZM7.59721 14.3611H8.98608V6.31944H7.59721V14.3611ZM11.0139 14.3611H12.4028V6.31944H11.0139V14.3611Z"
        fill={color}
      />
    </svg>
  );
};

export default DeleteIcon;
