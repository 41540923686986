import React from 'react';
import { Button } from '../ui/Button';
import { convertToCSV, generateUniqueFileName } from '@/lib/utils';
import { headerMapping } from '@/lib/csv/headerMapping';
import { formatDataForExport } from '@/lib/csv/csvUtils';

const DownloadCSVButton = ({ filteredRows }) => {
  const handleDownload = () => {
    const formattedData = formatDataForExport(filteredRows);

    // Sample CSV data
    const csvData = convertToCSV(formattedData, headerMapping);

    // Create a Blob containing the CSV data
    const blob = new Blob([csvData], { type: 'text/csv' });

    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = generateUniqueFileName('csv');

    // Append the link to the DOM and trigger a click event
    document.body.appendChild(downloadLink);
    downloadLink.click();

    // Remove the link from the DOM
    document.body.removeChild(downloadLink);
  };

  return <Button onClick={handleDownload}>EXPORT CSV</Button>;
};

export default DownloadCSVButton;
