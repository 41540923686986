import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ChevronLeft, Menu } from 'lucide-react';
import CustomDashedLine from './ui/DashedLine';
import { useAppContext } from '@/contexts/AppContext';
import HeaderButton from './ui/HeaderButton';

const Header = ({ title, previousRoute, primaryAction }) => {
  const { isMenuOpen, setIsMenuOpen } = useAppContext();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="flex items-center">
        {previousRoute && (
          <Link to={previousRoute}>
            <ChevronLeft size={18} className="mr-1" />
          </Link>
        )}
        <h1 className="text-lg font-bold">{title}</h1>
      </div>
      <div className="flex items-end md:gap-6 gap-0 mt-[-25px] mb-8">
        <CustomDashedLine className="" width={8} space={8} height={1} />
        {/* Hamburger Menu for Mobile View */}
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            <Menu size={26} />
          </button>
        </div>

        <HeaderButton {...primaryAction} />
      </div>
    </>
  );
};

Header.propTypes = {
  title: PropTypes.string.isRequired,
  previousRoute: PropTypes.string,
  primaryAction: PropTypes.shape({
    link: PropTypes.string,
    text: PropTypes.string,
  }),
};

export default Header;
