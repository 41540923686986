import React, {
  createContext,
  forwardRef,
  useEffect,
  useId,
  useRef,
  useState,
} from 'react';
import { Slot } from '@radix-ui/react-slot';
import { Controller, FormProvider, useFormContext } from 'react-hook-form';

import { cn } from '@/lib/utils';
import { Label } from '@/components/ui/label';
import { Input } from './Input';
import { Select, SelectContent, SelectItem, SelectTrigger } from './select';
import { CalendarIcon, Search } from 'lucide-react';
import { Calendar } from '@/components/ui/calendar';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { format } from 'date-fns';
import { Button } from './Button';
import { NOT_FOUND_TEXT } from '@/lib/constants';

const Form = FormProvider;

const FormFieldContext = React.createContext({});

const FormField = ({ ...props }) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const useFormField = () => {
  const fieldContext = React.useContext(FormFieldContext);
  const itemContext = React.useContext(FormItemContext);
  const { getFieldState, formState } = useFormContext();

  const fieldState = getFieldState(fieldContext.name, formState);

  if (!fieldContext) {
    throw new Error('useFormField should be used within <FormField>');
  }

  const { id } = itemContext;

  return {
    id,
    name: fieldContext.name,
    formItemId: `${id}-form-item`,
    formDescriptionId: `${id}-form-item-description`,
    formMessageId: `${id}-form-item-message`,
    ...fieldState,
  };
};

const FormItemContext = createContext({});

const FormItem = forwardRef(({ className, ...props }, ref) => {
  const id = useId();

  return (
    <FormItemContext.Provider value={{ id }}>
      <div ref={ref} className={cn('w-full', className)} {...props} />
    </FormItemContext.Provider>
  );
});
FormItem.displayName = 'FormItem';

const FormLabel = forwardRef(({ className, ...props }, ref) => {
  const { error, formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(error && 'text-destructive', className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
FormLabel.displayName = 'FormLabel';

export const FormInput = forwardRef(({ className, icon, ...props }, ref) => {
  const { error } = useFormField();

  const modifiedIcon =
    icon &&
    React.cloneElement(icon, {
      color: error ? 'red' : '#514F4F',
    });

  return (
    <Input
      ref={ref}
      icon={modifiedIcon}
      className={cn(
        error && 'border-destructive placeholder:text-destructive',
        className
      )}
      {...props}
    />
  );
});

const FormSelectTrigger = forwardRef(({ className, ...props }, ref) => {
  const { error } = useFormField();
  return (
    <SelectTrigger
      ref={ref}
      className={cn(error && 'border-destructive text-destructive', className)}
      {...props}
    />
  );
});

const FormControl = forwardRef(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();

  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
FormControl.displayName = 'FormControl';

const FormDescription = forwardRef(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn('text-sm text-muted-foreground', className)}
      {...props}
    />
  );
});
FormDescription.displayName = 'FormDescription';

const FormMessage = forwardRef(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn('text-sm font-medium text-destructive', className)}
      {...props}
    >
      {body}
    </p>
  );
});
FormMessage.displayName = 'FormMessage';

const FormFieldInput = ({ label, name, control, onChange, ...props }) => (
  <FormField
    control={control}
    name={name}
    render={({ field }) => (
      <FormItem>
        <FormLabel>{label}</FormLabel>
        <FormControl>
          <FormInput
            {...field}
            {...props}
            onChange={(e) => {
              field.onChange(e.target.value);
              onChange && onChange();
            }}
          />
        </FormControl>
      </FormItem>
    )}
  />
);

const FormFieldWrapper = ({ label, name, control, disabled, children }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            {React.cloneElement(children, { ...field, disabled })}
          </FormControl>
        </FormItem>
      )}
    />
  );
};

const FormFieldDropdown = ({
  label,
  name,
  control,
  placeholder,
  options,
  isConnected,
  disabled,
}) => {
  const { error } = useFormField();
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <div className="flex items-end w-full">
          <FormItem>
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <Select
                disabled={disabled}
                defaultValue={field.value}
                onValueChange={field.onChange}
              >
                <FormSelectTrigger
                  className={cn(
                    isConnected &&
                      error &&
                      'border-destructive text-destructive',
                    isConnected && 'rounded-l-none'
                  )}
                >
                  {field.value ? field.value : placeholder}
                </FormSelectTrigger>
                <SelectContent>
                  <SelectItem value="None">{placeholder}</SelectItem>
                  {options.map(
                    (opt, index) =>
                      opt && (
                        <SelectItem
                          key={index}
                          value={typeof opt === 'object' ? opt.value : opt}
                          disabled={opt === NOT_FOUND_TEXT}
                        >
                          {typeof opt === 'object' ? opt.label : opt}
                        </SelectItem>
                      )
                  )}
                </SelectContent>
              </Select>
            </FormControl>
          </FormItem>
        </div>
      )}
    />
  );
};

const FormFieldDropdownWithSearch = ({
  label,
  placeholder,
  name,
  control,
  options,
  onChange,
  isConnected,
  connectedRight,
  disabled,
}) => {
  const inputRef = useRef(null);
  const { error } = useFormField();

  const [open, setOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const newOptions = options.filter((opt) =>
      String(opt).toLowerCase().includes(lowercasedQuery)
    );

    setFilteredOptions(newOptions);
  }, [options, searchQuery]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSelectOption = (value, field) => {
    field.onChange(value);
    onChange && onChange({ name, value });
    setOpen(false);
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <div className="flex items-end w-full">
          <FormItem>
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <Select
                open={open}
                disabled={disabled}
                onOpenChange={setOpen}
                defaultValue={field.value}
                onValueChange={(value) => handleSelectOption(value, field)}
              >
                <FormSelectTrigger
                  className={cn(
                    isConnected &&
                      error &&
                      'border-destructive text-destructive',
                    isConnected && 'rounded-l-none',
                    connectedRight && 'rounded-r-none'
                  )}
                >
                  {field.value ? field.value : placeholder}
                </FormSelectTrigger>
                <SelectContent>
                  <Input
                    key={'search'}
                    ref={inputRef}
                    value={searchQuery}
                    className="border-none"
                    placeholder="Search options..."
                    icon={<Search color="#514F4F" width={16} />}
                    onChange={handleInputChange}
                    onBlur={() => inputRef.current.focus()}
                  />
                  <SelectItem value="None">{placeholder}</SelectItem>
                  {filteredOptions.length > 0 ? (
                    <>
                      {filteredOptions.map(
                        (opt, index) =>
                          opt && (
                            <SelectItem
                              key={index}
                              value={typeof opt === 'object' ? opt.value : opt}
                            >
                              {typeof opt === 'object' ? opt.label : opt}
                            </SelectItem>
                          )
                      )}
                    </>
                  ) : (
                    <SelectItem value={NOT_FOUND_TEXT} disabled>
                      {NOT_FOUND_TEXT}
                    </SelectItem>
                  )}
                </SelectContent>
              </Select>
            </FormControl>
          </FormItem>
          {React.isValidElement(connectedRight) && (
            <div className="max-w-[200px]">
              {React.cloneElement(connectedRight, {
                className: cn(
                  connectedRight.props.className,
                  'rounded-l-none border-l-0'
                ),
              })}
            </div>
          )}
        </div>
      )}
    />
  );
};

const FormDatePicker = forwardRef(({ field, disabled }, ref) => {
  const { error } = useFormField();

  const selectedDate = field.value
    ? typeof field.value === 'string'
      ? new Date(field.value)
      : field.value
    : null;

  return (
    <Popover>
      <PopoverTrigger asChild disabled={disabled}>
        <FormControl>
          <Button
            variant={'outline'}
            className={cn(
              'w-full justify-start text-left font-normal',
              (!field.value || disabled) && 'text-muted-foreground',
              error && 'border-destructive'
            )}
          >
            <CalendarIcon
              className="mr-2 h-4 w-4"
              color={error ? 'red' : '#514F4F'}
            />
            {selectedDate ? (
              format(selectedDate, 'PPP')
            ) : (
              <span className={error && 'text-destructive'}>Pick a date</span>
            )}
          </Button>
        </FormControl>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          ref={ref}
          mode="single"
          selected={selectedDate}
          onSelect={field.onChange}
        />
      </PopoverContent>
    </Popover>
  );
});

const FormFieldDate = ({ label, name, control, disabled }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>{label}</FormLabel>
            <FormDatePicker field={field} disabled={disabled} />
          </FormItem>
        );
      }}
    />
  );
};

export {
  useFormField,
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  FormFieldInput,
  FormFieldDropdown,
  FormFieldDropdownWithSearch,
  FormFieldDate,
  FormFieldWrapper,
};
