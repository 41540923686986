import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/Button';
import { useState } from 'react';
import useApi from '@/hooks/useApi';
import { DeleteWarning } from '../ui/alert-dialog';
import { Eye } from 'lucide-react';
import EditIcon from '../icons/EditIcon';
import DeleteIcon from '../icons/DeleteIcon';

export function RowActions({ row }) {
  const _id = row.original._id;
  const username = row.original.email;

  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);

  const { loading, makeApiCall } = useApi();
  const handleDelete = () => setOpenModal(true);

  const handleCancelDelete = () => setOpenModal(false);

  const handleConfirmDelete = async () => {
    await makeApiCall({
      url: '/drivers',
      method: 'DELETE',
      data: { _id },
    }).then(() =>
      makeApiCall({
        url: '/auth/delete-driver',
        method: 'post',
        data: { username },
      })
    );
    navigate(0); // reload page
  };

  const handleView = () => navigate(`/drivers/view?id=${_id}`);

  const handleEdit = () => navigate(`/drivers/edit?id=${_id}`);

  return (
    <>
      <DeleteWarning
        open={openModal}
        loading={loading}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <div className="flex justify-center">
        <Button variant="link" size="icon" onClick={handleView}>
          <Eye color="#015CAA" size={20} />
        </Button>
        <Button variant="link" size="icon" onClick={handleEdit}>
          <EditIcon color="#015CAA" />
        </Button>
        <Button variant="link" size="icon" onClick={handleDelete}>
          <DeleteIcon color={'#015CAA'} />
        </Button>
      </div>
    </>
  );
}
