import React from 'react';

const OrderIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 17.2118V3.13492L4.03847 4.05798L5.10578 3.13492L6.17306 4.05798L7.24037 3.13492L8.30767 4.05798L9.37498 3.13492L10.4423 4.05798L11.5096 3.13492L12.5769 4.05798L13.6442 3.13492L14.7115 4.05798L15.75 3.13492V17.2118L14.7115 16.2887L13.6442 17.2118L12.5769 16.2887L11.5096 17.2118L10.4423 16.2887L9.37498 17.2118L8.30767 16.2887L7.24037 17.2118L6.17306 16.2887L5.10578 17.2118L4.03847 16.2887L3 17.2118ZM5.0625 13.5051H13.6875V12.3801H5.0625V13.5051ZM5.0625 10.7358H13.6875V9.61087H5.0625V10.7358ZM5.0625 7.96662H13.6875V6.84164H5.0625V7.96662ZM4.12498 15.4984H14.625V4.84835H4.12498V15.4984Z"
        fill={color}
      />
    </svg>
  );
};

export default OrderIcon;
