import { Button } from '../ui/Button';
import { format } from 'date-fns';
import { Checkbox } from '@/components/ui/checkbox';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { RowActions } from './RowActions';
import { formatCurrency } from '@/lib/utils';

const createSortableHeader =
  (label) =>
  ({ column }) => {
    return (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        {column.getIsSorted() === 'asc' ? (
          <ChevronUp size={18} />
        ) : (
          <ChevronDown size={18} />
        )}
        <div className="ml-1">{label}</div>
      </Button>
    );
  };

export const commonColumns = [
  {
    accessorKey: 'id',
    header: createSortableHeader('ID'),
    cell: (props) => (
      <div className="whitespace-nowrap">{props.getValue()}</div>
    ),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'proNumber',
    header: createSortableHeader('ProNumber'),
    cell: ({ row }) => {
      const proNumber = row.getValue('proNumber');
      return proNumber ? (
        <div className="bg-[#FFC222] text-center rounded-xl py-0.5">
          {proNumber}
        </div>
      ) : (
        ''
      );
    },
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'pickupDate',
    header: createSortableHeader('Pick-up Date'),
    filterFn: 'dateRangeFilter',
    cell: (props) => {
      if (props.getValue()) {
        const parsedDate = new Date(props.getValue());
        return format(parsedDate, 'LLL dd, y');
      } else {
        return '';
      }
    },
  },
  {
    accessorKey: 'mainTruckNumber',
    header: createSortableHeader('Truck'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'carrierCompanyName',
    header: createSortableHeader('Carrier'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'material',
    header: createSortableHeader('Material'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'haulTotal',
    header: createSortableHeader('Haul Total'),
    filterFn: 'multiSelectFilter',
    cell: (props) => {
      if (props.getValue()) {
        return formatCurrency(props.getValue(), true);
      } else {
        return '';
      }
    },
  },
  {
    accessorKey: 'truckTotal',
    header: createSortableHeader('Truck Total'),
    filterFn: 'multiSelectFilter',
    cell: (props) => {
      if (props.getValue()) {
        return formatCurrency(props.getValue(), true);
      } else {
        return '';
      }
    },
  },
  {
    accessorKey: 'status',
    header: createSortableHeader('Status'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'ticketNumber',
    header: createSortableHeader('Ticket'),
    filterFn: 'multiSelectFilter',
  },
];

const checkboxColumn = {
  id: 'select',
  header: ({ table }) => (
    <Checkbox
      checked={
        table.getIsAllPageRowsSelected() ||
        (table.getIsSomePageRowsSelected() && 'indeterminate')
      }
      onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
      aria-label="Select all"
    />
  ),
  cell: ({ row }) => (
    <Checkbox
      checked={row.getIsSelected()}
      onCheckedChange={(value) => row.toggleSelected(!!value)}
      aria-label="Select row"
    />
  ),
  enableSorting: false,
  enableHiding: false,
};

export const desktopColumns = [
  checkboxColumn,
  ...commonColumns,
  {
    accessorKey: 'actions',
    header: 'Actions',
    cell: (props) => <RowActions row={props.row} />,
  },
];

export const mobileColumns = [
  {
    ...checkboxColumn,
    meta: {
      className: 'sticky',
    },
  },
  {
    accessorKey: 'actions',
    header: 'Actions',
    cell: (props) => <RowActions row={props.row} />,
    meta: {
      className: 'sticky',
    },
  },
  ...commonColumns,
];
