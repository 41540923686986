'use client';
import React, { useState } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import { Table } from '../ui/table';

import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import OrderFilters from './OrderFilters';
import { dateRangeFilter, multiSelectFilter } from '@/lib/utils';
import Pagination from '../ui/pagination';
import OrderDispatcher from './OrderDispatcher';
import { DEFAULT_ORDER_STATUS } from '@/lib/constants';
import DownloadCSVButton from './DownloadCSVButton';
import TableBody from '../ui/TableBody';
import TableHeader from '../ui/TableHeader';

export function DataTable({ columns, data }) {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([
    {
      id: 'status',
      value: DEFAULT_ORDER_STATUS,
    },
  ]);

  const [pageSize, setPageSize] = useState(5);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    enableRowSelection: (row) => row.original.status === 'Assigned',
    filterFns: {
      dateRangeFilter,
      multiSelectFilter,
    },
    state: {
      sorting,
      columnFilters,
      rowSelection,
      pagination: {
        pageIndex,
        pageSize,
      },
    },
  });

  return (
    <>
      <ScrollArea>
        <OrderFilters data={data} table={table} />
        <ScrollBar orientation="horizontal" transparent />
      </ScrollArea>
      <Table>
        <TableHeader headerGroups={table.getHeaderGroups()} />
        <TableBody columns={columns} rows={table.getRowModel().rows} />
      </Table>
      <div className="flex justify-between mt-4 mb-20">
        <DownloadCSVButton filteredRows={table.getFilteredRowModel().rows} />
        <OrderDispatcher selectedRows={table.getSelectedRowModel().rows} />
      </div>
      <Pagination
        data={data}
        table={table}
        pageSize={pageSize}
        pageIndex={pageIndex}
        setPageSize={setPageSize}
        setPageIndex={setPageIndex}
      />
    </>
  );
}
