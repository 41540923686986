import React from 'react';
import { Button } from './Button';
import { Download, Trash2 } from 'lucide-react';
import { Label } from './label';
import axios from 'axios';
import useApi from '@/hooks/useApi';
import { createBlob, createDownloadLink } from '@/lib/utils';

const UploadedFiles = ({ files, remove, isViewMode }) => {
  const { makeApiCall } = useApi();
  if (files.length === 0) return;

  const handleDelete = (index) => {
    remove(index);
  };

  const getSignedURL = async (path) => {
    const result = await makeApiCall({
      url: '/files/getSignedURLs',
      method: 'POST',
      data: { path },
    });

    return result.data || null;
  };

  const downloadFile = async (fileUrl) => {
    const result = await axios.get(fileUrl, { responseType: 'arraybuffer' });
    return result || null;
  };

  const handleDownload = async (path) => {
    try {
      const fileUrl = await getSignedURL(path);
      if (!fileUrl) {
        console.error('Error: No signed URL received');
        return;
      }

      const result2 = await downloadFile(fileUrl);
      if (!result2) {
        console.error('Error: Unable to download the file');
        return;
      }

      const blob = createBlob(result2.data, result2.headers['content-type']);
      createDownloadLink(blob, path);
    } catch (error) {
      console.error('Error during file download:', error);
    }
  };

  return (
    <div>
      <Label>Uploaded</Label>
      <ul className="text-sm">
        {files.map((file, index) => (
          <li
            key={index}
            className="flex justify-between items-center border mb-2 py-0.5 px-3 rounded-md"
          >
            {file.name}{' '}
            <div>
              {file?.path && (
                <Button
                  size="icon"
                  variant="ghost"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDownload(file.path);
                  }}
                >
                  <Download width={16} />
                </Button>
              )}{' '}
              {!isViewMode && (
                <Button
                  size="icon"
                  variant="ghost"
                  className="w-8"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete(index);
                  }}
                >
                  <Trash2 width={16} color="#E70A0A" />
                </Button>
              )}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UploadedFiles;
