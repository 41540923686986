const driverDefaultValues = {
  carrierCode: '',
  carrierCompanyName: '',
  contactName: '',
  contactEmail: '',
  contactNumber: '',
  dotNumber: '',
  driverId: '',
  driverName: '',
  driverOrCarrierPercentage: '',
  mainTruckNumber: '',
  truckNumber2: '',
  truckNumber3: '',
  mainTrailerType: '',
  numberMainTrailerType: '',
  trailerType2: '',
  numberTrailerType2: '',
  trailerType3: '',
  numberTrailerType3: '',
  companyOrOwner: '',
  phoneNumber: '',
  email: '',
  state: '',
  city: '',
  zipCode: '',
  address: '',
  _id: '',
  creationDate: '',
  birthDate: '',
};

export default driverDefaultValues;
