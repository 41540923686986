import { clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { startOfDay } from 'date-fns';

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function removeDuplicates(options) {
  return Array.from(new Set(options));
}
export function removeDuplicatesByProperty(array, property) {
  const uniqueValues = new Set();

  for (const item of array) {
    const value = item[property];
    uniqueValues.add(value);
  }

  // Convert the Set of unique values back to an array
  const result = Array.from(uniqueValues).sort();

  return result;
}

export function multiSelectFilter(row, columnId, selectedValue) {
  return selectedValue.length === 0
    ? row
    : selectedValue.includes(row.original[columnId]);
}

export function dateRangeFilter(row, columnId, date) {
  const selectedDate = new Date(row.original[columnId]);

  if (date === undefined) {
    return row;
  }
  if (date.to === undefined) {
    // If only 'from' date exists, check if selectedDate is after that date
    return date.from <= selectedDate && selectedDate <= startOfDay(date.from);
  } else {
    // If both 'from' and 'to' dates exist, check if selectedDate is between them
    return (
      startOfDay(date.from) <= selectedDate &&
      selectedDate <= startOfDay(date.to)
    );
  }
}

export function customFilter(value, search) {
  if (value.toLowerCase().includes(search.toLowerCase())) return 1;
  return 0;
}

export function filterDataByType(data, type) {
  // Using the filter method to filter items by type
  const filteredData = data.filter((item) => item.type === type);

  // Returning an array with values of the same type
  return filteredData;
}

export function isArray(value) {
  return Array.isArray(value) && value.length > 0;
}

export const formatCurrency = (value, includeDollarSymbol = false) => {
  let number;

  // Check if the input is a string with a specific format
  if (typeof value === 'string') {
    // Remove commas and convert to a numeric value
    number = parseFloat(value.replace(/,/g, ''));
  } else if (typeof value === 'number') {
    number = value;
  }

  // Check if the numeric value is valid
  if (isNaN(number)) {
    return;
  }

  // Use toLocaleString to add commas and format as currency
  const formattedNumber = Number(number).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // Include or remove dollar symbol based on the flag
  return includeDollarSymbol ? formattedNumber : formattedNumber.slice(1);
};

export const parseCurrency = (formattedCurrency) => {
  if (!formattedCurrency) {
    return 0;
  }
  // If the parameter is already a number, return it directly
  if (typeof formattedCurrency === 'number') {
    return formattedCurrency;
  }

  // Remove non-numeric characters and parse as a floating-point number
  const numericValue = parseFloat(formattedCurrency.replace(/[^0-9.-]+/g, ''));

  // Check if the parsing was successful
  if (isNaN(numericValue)) {
    return;
  }

  return numericValue;
};

export function generateS3FileKey(name) {
  // Generate a timestamp or use any other unique identifier if needed
  const timestamp = Date.now();

  // Combine the name, timestamp, and extension to create the file key
  const fileKey = `${timestamp}_${name.replace(/\s/g, '_')}`;

  return fileKey;
}

export function generateUniqueFileName(extension) {
  // Get the current date and time
  const currentDate = new Date();

  // Build the file name using a combination of date, time, and unique identifier
  const fileName = `report_${currentDate.getFullYear()}${(
    currentDate.getMonth() + 1
  )
    .toString()
    .padStart(2, '0')}${currentDate
    .getDate()
    .toString()
    .padStart(2, '0')}.${extension}`;

  return fileName;
}

export const isValidDate = (date) => date instanceof Date;

export const convertToCSV = (array, headerMapping) => {
  const headers = Object.keys(headerMapping);
  const renamedHeaders = headers.map((key) => headerMapping[key]);

  const escapeCSVValue = (value) => {
    // If the value is undefined, return an empty string
    if (value === undefined || value === null) {
      return '';
    }

    // If the value contains a comma, enclose it in double quotes
    if (String(value).includes(',')) {
      return `"${String(value).replace(/"/g, '""')}"`;
    }

    return String(value);
  };

  const rows = array.map((obj) =>
    headers.map((key) => escapeCSVValue(obj[key]))
  );

  return [renamedHeaders.join(','), ...rows.map((row) => row.join(','))].join(
    '\n'
  );
};

export const parseStringToDate = (date) => {
  if (!date) {
    return '';
  }

  const formattedDate = new Date(date);
  return formattedDate.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

export const createDownloadLink = (blob, path) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = window.URL.createObjectURL(blob);
  downloadLink.download = path;

  document.body.appendChild(downloadLink);
  downloadLink.click();

  document.body.removeChild(downloadLink);
};

export const createBlob = (fileData, contentType) => {
  return new Blob([fileData], {
    type: contentType,
  });
};

export function isEmpty(obj) {
  if (obj === undefined || obj === null) {
    return true; // Treat undefined or null as empty
  }

  // Check if the number of keys is zero
  return Object.keys(obj).length === 0;
}
