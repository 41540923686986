import {
  accesorialConfig,
  rateConfig,
  shipperConfig,
  trailerTypeConfig,
  unitsConfig,
  agentConfig,
  userConfig,
  rolesConfig,
} from '@/lib/settings/configs';
import {
  accesorialsColumns,
  rateColumns,
  shipperColumns,
  trailerTypesColumns,
  unitsColumns,
  agentColumns,
  userColumns,
  rolesColumns,
} from '@/lib/settings/columns';

// Define an object to store configurations and columns for each tab
export const tabsConfig = {
  shipper: {
    formTitle: 'NEW SHIPPER',
    formConfig: shipperConfig,
    columns: shipperColumns,
  },
  consignee: {
    formTitle: 'NEW CONSIGNEE',
    formConfig: shipperConfig,
    columns: shipperColumns,
  },
  billTo: {
    formTitle: 'NEW BILL TO',
    formConfig: shipperConfig,
    columns: shipperColumns,
  },
  rate: {
    formTitle: 'NEW RATE',
    formConfig: rateConfig,
    columns: rateColumns,
  },
  accesorials: {
    formTitle: 'NEW ACCESORIAL',
    formConfig: accesorialConfig,
    columns: accesorialsColumns,
  },
  unit: {
    formTitle: 'NEW UNIT',
    formConfig: unitsConfig,
    columns: unitsColumns,
  },
  trailerTypes: {
    formTitle: 'NEW TRAILER TYPE',
    formConfig: trailerTypeConfig,
    columns: trailerTypesColumns,
  },
};

export const userTabsConfig = {
  agent: {
    formTitle: 'NEW AGENT',
    formConfig: agentConfig,
    columns: agentColumns,
  },
  user: {
    formTitle: 'NEW USER',
    formConfig: userConfig,
    columns: userColumns,
  },
  roles: {
    formTitle: 'NEW ROLE',
    formConfig: rolesConfig,
    columns: rolesColumns,
  },
};
