import { Save, X } from 'lucide-react';
import { Button } from '../ui/Button';

export default function Buttons({
  loading,
  handleSubmit,
  handleCancel,
  disabled,
  isViewMode,
}) {
  return (
    <div className="flex justify-center gap-4 mt-2">
      {isViewMode ? (
        <Button type="button" className="px-8" onClick={handleCancel}>
          BACK
        </Button>
      ) : (
        <>
          <Button
            type="button"
            variant="outline"
            className="px-6"
            onClick={handleCancel}
          >
            CANCEL <X width={18} className="ml-1" />
          </Button>
          <Button
            type="submit"
            className="px-8"
            onClick={handleSubmit}
            disabled={disabled}
            loading={loading}
          >
            SAVE <Save width={18} className="ml-2" />
          </Button>
        </>
      )}
    </div>
  );
}
