import React, { useEffect, useMemo, useState } from 'react';
import { Plus, Trash2 } from 'lucide-react';

import {
  Select,
  SelectValue,
  SelectContent,
  SelectTrigger,
  SelectItem,
} from '../ui/select';
import Row from '../ui/Row';
import { Input } from '../ui/Input';
import { Label } from '../ui/label';
import { Button } from '../ui/Button';
import { FormFieldInput } from '../ui/form';
import { ACCESORIALS_OPTIONS } from '@/lib/constants';
import { useFieldArray } from 'react-hook-form';
import {
  calculateHaulTotal,
  calculateNewAccTotal,
  calculateTruckTotal,
  handleCustomAmount,
} from './functions';
import { Checkbox } from '../ui/checkbox';
import { formatCurrency } from '@/lib/utils';

const Accessorials = ({ form, isViewMode }) => {
  const [type, setType] = useState('');
  const { fields, append, update, remove } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'accesorials', // unique name for your Field Array
  });

  const generateRefId = (fieldName, index) => `${fieldName}_${index}`;

  const [lastModifiedIndex, setLastModifiedIndex] = useState(null);

  const refs = useMemo(
    () =>
      fields.map((_, index) => ({
        percentage: { current: null },
        amount: { current: null },
        truckPercentage: { current: null },
        newAccTotal: { current: null },
        id: generateRefId('row', index),
      })),
    [fields]
  );

  const [preventFocus, setPreventFocus] = useState(false);

  const handleOutsideClick = (event) => {
    if (
      refs.some(
        (rowRefs) =>
          !rowRefs.percentage?.current?.contains(event.target) ||
          !rowRefs.amount?.current?.contains(event.target) ||
          !rowRefs.truckPercentage?.current?.contains(event.target) ||
          !rowRefs.newAccTotal?.current?.contains(event.target)
      )
    ) {
      setPreventFocus(true);
      setLastModifiedIndex(null);
    }
  };
  useEffect(() => {
    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  useEffect(() => {
    if (
      !preventFocus &&
      lastModifiedIndex !== null &&
      refs[lastModifiedIndex]
    ) {
      const currentRef = refs[lastModifiedIndex][type];
      if (currentRef && currentRef.current) {
        currentRef.current.focus();
      }
    }
  }, [type, preventFocus, fields, lastModifiedIndex]);

  const handleAddRow = (e) => {
    e.preventDefault();
    append({
      option: '',
      percentage: '',
      amount: '',
      changeTruckPercentage: false,
      truckPercentage: '',
      newAccTotal: '',
    });
  };

  const handleInputChange = (index, field, value) => {
    update(index, { ...fields[index], [field]: value });

    if (field === 'percentage') {
      calculateHaulTotal(form);
    }
    if (field === 'amount') {
      handleCustomAmount(form);
    }
    if (field === 'truckPercentage') {
      calculateNewAccTotal(form);
    }
    if (field === 'newAccTotal' || field === 'changeTruckPercentage') {
      handleTruckTotal();
    }

    setType(field);
    setPreventFocus(false);
    setLastModifiedIndex(index);
  };

  const handleRemoveRow = (index) => {
    remove(index);
    calculateHaulTotal(form);
  };

  const handleAccesorialsFormat = () => {
    const { accesorials } = form.getValues();
    const newAccesorials = accesorials.map((item) => ({
      ...item,
      amount: formatCurrency(item.amount),
      newAccTotal: formatCurrency(item.newAccTotal),
    }));
    form.setValue('accesorials', newAccesorials);
    setPreventFocus(true);
  };

  const handleTruckTotal = () => calculateTruckTotal(form);

  const showSubtotalField = fields.some((row) => row?.changeTruckPercentage);
  return (
    <>
      {fields.map((field, index) => (
        <Row key={field.id} className="items-start">
          <Row>
            {!isViewMode &&
              (index === 0 ? (
                <Button onClick={handleAddRow} className="mt-6">
                  <Plus width={18} />
                </Button>
              ) : (
                <Button onClick={() => handleRemoveRow(index)} className="mt-6">
                  <Trash2 width={18} />
                </Button>
              ))}
            <div className="flex-1 flex-col space-y-2">
              <Dropdown
                disabled={isViewMode}
                index={index}
                options={ACCESORIALS_OPTIONS}
                value={field.option}
                onChange={(e) => {
                  handleInputChange(index, 'option', e.target.value);
                }}
              />
              {!isViewMode && (
                <div className="flex space-x-1 items-center pt-1">
                  <Checkbox
                    checked={field.changeTruckPercentage}
                    onCheckedChange={(value) =>
                      handleInputChange(index, 'changeTruckPercentage', value)
                    }
                    id="changeTruckPercentage"
                    className="h-5 w-5"
                  />
                  <label
                    htmlFor="changeTruckPercentage"
                    className="text-sm text-gray-900 font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                  >
                    Change Truck % for the accesorial
                  </label>
                </div>
              )}
            </div>
          </Row>
          <div className="grid grid-cols-2 w-full gap-5">
            <div>
              <Label>% of Total</Label>
              <Input
                disabled={isViewMode}
                suffix="%"
                inputMode="numeric"
                placeholder="000000"
                value={field.percentage}
                onChange={(e) =>
                  handleInputChange(index, 'percentage', e.target.value)
                }
                ref={(el) => {
                  if (refs[index]?.percentage) {
                    refs[index].percentage.current = el;
                  }
                }}
                id={`percentage-${refs[index]?.id}`}
              />
            </div>
            <div>
              <Label>Acc. Total</Label>
              <Input
                type="money"
                placeholder="0.00"
                value={field.amount}
                disabled={isViewMode}
                onChange={(e) =>
                  handleInputChange(index, 'amount', e.target.value)
                }
                onBlur={handleAccesorialsFormat}
                ref={(el) => {
                  if (refs[index]?.amount) {
                    refs[index].amount.current = el;
                  }
                }}
                id={`amount-${refs[index]?.id}`}
              />
            </div>
            {field?.changeTruckPercentage && (
              <>
                <div>
                  <Label>Truck %</Label>
                  <Input
                    disabled={isViewMode}
                    suffix="%"
                    inputMode="numeric"
                    placeholder="000000"
                    value={field.truckPercentage}
                    onChange={(e) =>
                      handleInputChange(
                        index,
                        'truckPercentage',
                        e.target.value
                      )
                    }
                    ref={(el) => {
                      if (refs[index]?.truckPercentage) {
                        refs[index].truckPercentage.current = el;
                      }
                    }}
                    id={`truckPercentage-${refs[index]?.id}`}
                  />
                </div>
                <div>
                  <Label>New Acc. Total</Label>
                  <Input
                    disabled={isViewMode}
                    type="money"
                    placeholder="0.00"
                    value={field.newAccTotal}
                    onChange={(e) =>
                      handleInputChange(index, 'newAccTotal', e.target.value)
                    }
                    onBlur={handleAccesorialsFormat}
                    ref={(el) => {
                      if (refs[index]?.newAccTotal) {
                        refs[index].newAccTotal.current = el;
                      }
                    }}
                    id={`newAccTotal-${refs[index]?.id}`}
                  />
                </div>
              </>
            )}
          </div>
        </Row>
      ))}
      <div className="md:grid grid-cols-4 gap-5">
        <div className="col-start-4">
          <FormFieldInput
            disabled
            type="money"
            label="Haul Total"
            name="haulTotal"
            placeholder="0.00"
            control={form.control}
          />
        </div>
      </div>
      {showSubtotalField && (
        <div className="md:grid grid-cols-4 gap-5">
          <div className="col-start-4">
            <FormFieldInput
              disabled
              type="money"
              label="Truck Subtotal"
              name="truckSubtotal"
              placeholder="0.00"
              control={form.control}
            />
          </div>
        </div>
      )}
      <div className="md:grid grid-cols-4 gap-5">
        <div className="col-start-3 mb-3 md:mb-0">
          <FormFieldInput
            suffix="%"
            inputMode="numeric"
            label="Driver's/Carrier's %"
            name="driverOrCarrierPercentage"
            placeholder="000000"
            onChange={handleTruckTotal}
            control={form.control}
          />
        </div>
        <FormFieldInput
          disabled
          type="money"
          label="Truck Total"
          name="truckTotal"
          placeholder="0.00"
          control={form.control}
        />
      </div>
    </>
  );
};

const Dropdown = ({ index, options, disabled, value, ...props }) => {
  return (
    <div {...props}>
      <Label>Accesorial {index + 1}</Label>
      <Select disabled={disabled} defaultValue={value}>
        <SelectTrigger>
          <SelectValue placeholder="Choose an option" />
        </SelectTrigger>
        <SelectContent>
          <SelectItem key={'default-accesorial'} value="None">
            Choose an option
          </SelectItem>
          {options.map((opt, optionIndex) => (
            <SelectItem
              key={optionIndex}
              value={typeof opt === 'object' ? opt.value : opt}
            >
              {typeof opt === 'object' ? opt.label : opt}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
};

export default Accessorials;
