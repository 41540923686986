import React, { useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { Combobox } from '../ui/combobox';
import { TRAILER_TYPE_OPTIONS } from '@/lib/constants';
import { removeDuplicatesByProperty } from '@/lib/utils';

const DriverFilters = ({ data, table }) => {
  const [filtersVisible, setFiltersVisible] = useState(false);

  const toggleFilters = () => {
    setFiltersVisible(!filtersVisible);
  };

  const setFilterValue = (propertyName, newValues) => {
    table?.getColumn(propertyName)?.setFilterValue(newValues);
  };

  // Memoized options for each property
  const memoizedOptions = useMemo(() => {
    return {
      driverId: removeDuplicatesByProperty(data, 'driverId'),
      driverName: removeDuplicatesByProperty(data, 'driverName'),
      mainTruckNumber: removeDuplicatesByProperty(data, 'mainTruckNumber'),
      carrierCompanyName: removeDuplicatesByProperty(
        data,
        'carrierCompanyName'
      ),
      companyOrOwner: removeDuplicatesByProperty(data, 'companyOrOwner'),
      city: removeDuplicatesByProperty(data, 'city'),
    };
  }, [data]);

  return (
    <div className="mb-5">
      <div
        onClick={toggleFilters}
        className="flex text-sm text-[#A8A7A7] cursor-pointer"
      >
        <ChevronDown size={16} /> Filters
      </div>
      {filtersVisible && (
        <div className="flex gap-7 mt-6">
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.driverId}
            label="Driver's ID"
            propertyName="driverId"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.driverName}
            label="Name"
            propertyName="driverName"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.mainTruckNumber}
            label="Truck #"
            propertyName="mainTruckNumber"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.carrierCompanyName}
            label="Carrier"
            propertyName="carrierCompanyName"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={TRAILER_TYPE_OPTIONS.sort()}
            label="Trailer Type"
            propertyName="mainTrailerType"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.companyOrOwner}
            label="Company/Owner"
            propertyName="companyOrOwner"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.city}
            label="Location"
            propertyName="city"
          />
        </div>
      )}
    </div>
  );
};

export default DriverFilters;
