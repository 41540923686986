import * as z from 'zod';
import { Button } from '@/components/ui/Button';
import { Plus, Settings } from 'lucide-react';
import { useForm } from 'react-hook-form';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormFieldDropdownWithSearch,
  FormFieldInput,
} from '@/components/ui/form';
import { zodResolver } from '@hookform/resolvers/zod';
import ModalButtons from '@/components/settings/ModalButtons';
import { isArray, isEmpty } from '@/lib/utils';
import useApi from '@/hooks/useApi';
import { useSettingsContext } from '@/contexts/SettingsContext';

export function SettingsForm({ title, formConfig }) {
  const formSchema = z.object(formConfig.schema);
  const defaultValues = formConfig.defaultValues || {};

  const {
    options,
    activeTab,
    isViewMode,
    selectedSetting,
    setSelectedSetting,
    isModalOpen,
    setIsModalOpen,
    getOrderSettingsData,
  } = useSettingsContext();

  const { loading, makeApiCall } = useApi();

  const form = useForm({
    resolver: zodResolver(formSchema),
    values: isEmpty(selectedSetting) ? defaultValues : selectedSetting,
    defaultValues,
  });

  const handleCancel = async () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async () => {
    // Handle form submission
    let response;
    const formData = {
      type: activeTab,
      ...form.getValues(),
    };
    if (formData?._id) {
      response = await makeApiCall({
        url: '/order-settings',
        method: 'PUT',
        data: formData,
      });
    } else {
      response = await makeApiCall({
        url: '/order-settings',
        method: 'POST',
        data: formData,
      });
    }

    if ([200, 201].includes(response?.status)) {
      await getOrderSettingsData();
      setIsModalOpen(false);
      form.reset();
    }
  };

  const handleCreateButton = () => {
    setSelectedSetting({});
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={setIsModalOpen}>
      <DialogTrigger asChild onClick={handleCreateButton}>
        <Button className="text-xs tracking-wider font-semibold">
          {title} <Plus className="ml-1" width={17} />
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-2xl">
        <DialogHeader>
          <DialogTitle className="flex items-center text-md">
            <Settings className="mr-2" width={20} />
            {title}
          </DialogTitle>
        </DialogHeader>
        <div className="my-10">
          <Form {...form}>
            <form
              onSubmit={form.handleSubmit(handleSubmit)}
              className="grid grid-cols-2 gap-4"
            >
              {isArray(formConfig.fields) &&
                formConfig.fields.map((field) => (
                  <div key={field.name} className={field.className}>
                    {field?.type === 'select' ? (
                      <FormFieldDropdownWithSearch
                        label={field.label}
                        name={field.name}
                        control={form.control}
                        options={options[field.name] || []}
                        placeholder={field.placeholder}
                      />
                    ) : (
                      <FormFieldInput
                        type={field?.type}
                        disabled={isViewMode}
                        label={field.label}
                        name={field.name}
                        control={form.control}
                        placeholder={field.placeholder}
                      />
                    )}
                  </div>
                ))}
            </form>
          </Form>
        </div>
        <ModalButtons
          loading={loading}
          isViewMode={isViewMode}
          handleSubmit={handleSubmit}
          handleCancel={handleCancel}
          disabled={!form.formState.isValid || loading}
        />
      </DialogContent>
    </Dialog>
  );
}
