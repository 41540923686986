import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Button } from './Button';
import Dropdown from './dropdown';
import { cn, isArray } from '@/lib/utils';
import { useState } from 'react';

const itemsPerPageOptions = [
  { value: 15, label: '15', isItem: true },
  { value: 20, label: '20', isItem: true },
  { value: 35, label: '35', isItem: true },
  { value: 'All', label: 'All', isItem: true },
];

const Pagination = ({
  data,
  table,
  pageIndex,
  pageSize,
  setPageIndex,
  setPageSize,
  className,
}) => {
  const [isAllSelected, setIsAllSelected] = useState(false);
  const startItem = pageIndex * pageSize + 1;
  const endItem = (pageIndex + 1) * pageSize;

  const infoString = `${startItem}-${endItem} of ${
    isArray(data) ? data.length : 0
  }`;

  const handlePageSize = (selectedOption) => {
    if (selectedOption === 'All') {
      setPageSize(data.length);
      setIsAllSelected(true);
    } else {
      setPageSize(selectedOption);
      setIsAllSelected(false);
    }
  };

  return (
    <div
      className={cn(
        'flex items-center justify-center gap-3 py-5 fixed left-0 bottom-0 w-full bg-white z-10',
        'md:w-[calc(100%-13rem)] md:left-[13rem]',
        className
      )}
    >
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setPageIndex(pageIndex - 1)}
        disabled={!table.getCanPreviousPage()}
      >
        <ChevronLeft color="#514F4F" width={18} />
      </Button>
      <div className="text-sm pr-1 md:block hidden">Items per page</div>
      <Dropdown
        onChange={handlePageSize}
        options={itemsPerPageOptions}
        trigger={isAllSelected ? 'All' : pageSize}
      />
      <div className="text-sm pl-1">{infoString}</div>
      <Button
        variant="ghost"
        size="icon"
        onClick={() => setPageIndex(pageIndex + 1)}
        disabled={!table.getCanNextPage()}
      >
        <ChevronRight color="#514F4F" width={18} />
      </Button>
    </div>
  );
};

export default Pagination;
