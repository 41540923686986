import { Button } from '../ui/Button';
import { ChevronDown, ChevronUp } from 'lucide-react';
import { RowActions } from './RowActions';

const createSortableHeader =
  (label) =>
  ({ column }) => {
    return (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}
      >
        {column.getIsSorted() === 'asc' ? (
          <ChevronUp size={18} />
        ) : (
          <ChevronDown size={18} />
        )}
        <div className="ml-1">{label}</div>
      </Button>
    );
  };

const commonColumns = [
  {
    accessorKey: 'driverId',
    header: createSortableHeader("Driver's ID"),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'driverName',
    header: createSortableHeader('Name'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'mainTruckNumber',
    header: createSortableHeader('Truck #'),
    cell: ({ row }) => {
      return (
        <div className="text-center flex justify-center">
          <div className="w-40">{row.getValue('mainTruckNumber')}</div>
        </div>
      );
    },
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'carrierCompanyName',
    header: createSortableHeader('Carrier'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'mainTrailerType',
    header: createSortableHeader('Trailer Type'),
    cell: ({ row }) => {
      return (
        <div className="text-center flex justify-center">
          <div className="w-40">{row.getValue('mainTrailerType')}</div>
        </div>
      );
    },
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'companyOrOwner',
    header: createSortableHeader('Company/Owner'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'city',
    header: createSortableHeader('Location'),
    filterFn: 'multiSelectFilter',
  },
  {
    accessorKey: 'termsAgreed',
    header: createSortableHeader('Agreed Terms'),
    filterFn: 'multiSelectFilter',
  },
];

export const mobileColumns = [
  {
    accessorKey: '_id',
    header: 'Actions',
    cell: (props) => <RowActions row={props.row} />,
    meta: {
      className: 'sticky',
    },
  },
  ...commonColumns,
];

export const desktopColumns = [
  ...commonColumns,
  {
    accessorKey: '_id',
    header: 'Actions',
    cell: (props) => <RowActions row={props.row} />,
  },
];
