import React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Form, FormFieldInput, FormFieldDropdown } from '../ui/form';
import * as z from 'zod';
import { Button } from '../ui/Button';
import Row from '../ui/Row';
import { UNITS_OPTIONS } from '@/lib/constants';
import FileUploadTicket from '../ui/FileUploadTicket';
import axios from 'axios';
import Cookies from 'js-cookie';

const formSchema = z.object({
  ticketNumber: z.string(),
  quantity: z.string(),
  quantityUnit: z.string(),
  files: z.any(),
});

const UploadTicket = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const baseURL = process.env.REACT_APP_ROOT_API;

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ticketNumber: '',
      quantity: '',
      quantityUnit: 'Ton',
      files: [],
    },
    values: {},
  });

  const isViewMode = false;

  const onSubmit = async (values) => {
    const _id = searchParams.get('id');
    await axios
      .post(
        `${baseURL}/orders/updateOrderTicketQuantity`,
        {
          ...values,
          _id,
        },
        {
          headers: {
            Authorization: Cookies.get('accessToken'),
          },
        }
      )
      .then(() => navigate(-1))
      .catch((e) => console.log(e));
  };

  return (
    <div style={{ padding: '10px' }}>
      <h1
        style={{ fontWeight: 'bold', fontSize: '20px', marginBottom: '20px' }}
      >
        UPLOAD TICKET AND QUANTITY
      </h1>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <Row className="mb-2">
            <FileUploadTicket control={form.control} isViewMode={isViewMode} />
          </Row>
          <Row className="mb-2">
            <FormFieldInput
              label="Quantity"
              name="quantity"
              placeholder="0000000"
              control={form.control}
              disabled={isViewMode}
              connectedRight={
                <FormFieldDropdown
                  isConnected
                  placeholder="Ton"
                  options={UNITS_OPTIONS}
                  name="quantityUnit"
                  disabled={isViewMode}
                  control={form.control}
                />
              }
            />
          </Row>
          <div className="float-right py-[10px] space-x-2">
            <Button
              type="button"
              variant="outline"
              onClick={() => navigate(-1)}
            >
              CANCEL
            </Button>
            <Button type="submit">SAVE</Button>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default UploadTicket;
