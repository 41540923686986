import {
  cloneElement,
  isValidElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormControl, FormField, FormInput, FormItem, FormLabel } from './form';
import { ScrollArea } from './scroll-area';
import { cn } from '@/lib/utils';

const SelectableInputText = ({
  label,
  name,
  control,
  options,
  connectedRight,
  onChange,
  ...props
}) => {
  const componentRef = useRef(null);
  const [isFocused, setFocused] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    const lowercasedQuery = searchQuery.toLowerCase();
    const newOptions = options.filter((opt) =>
      String(opt).toLowerCase().includes(lowercasedQuery)
    );

    setFilteredOptions(newOptions);
  }, [options, searchQuery]);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        componentRef.current &&
        !componentRef.current.contains(event.target)
      ) {
        setFocused(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, []);

  const handleInputChange = (event, field) => {
    field.onChange(event.target.value);
    setSearchQuery(event.target.value);
    onChange && onChange();
  };

  const handleSelectOption = (value, field) => {
    field.onChange(value);
    setFocused(false);
    setSearchQuery(value);
    onChange && onChange();
  };

  const handleFocus = () => {
    setFocused(true);
  };
  const renderOptions = (field) => {
    const optionsToRender =
      filteredOptions.length > 0 ? filteredOptions : options;

    return (
      <ScrollArea className="max-h-96 w-full">
        {optionsToRender.length > 0 ? (
          optionsToRender.map((option, index) => (
            <div
              key={index}
              onClick={() => handleSelectOption(option, field)}
              className="items-center rounded-sm py-1.5 pl-6 pr-2 text-sm outline-none hover:bg-accent hover:text-accent-foreground"
            >
              {option}
            </div>
          ))
        ) : (
          <div className="text-sm pl-6 pr-2 py-1.5 text-gray">
            No options available
          </div>
        )}
      </ScrollArea>
    );
  };

  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <div ref={componentRef} className="flex items-end w-full relative">
          <FormItem>
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <>
                <FormInput
                  {...props}
                  value={field.value}
                  onChange={(e) => handleInputChange(e, field)}
                  onFocus={handleFocus}
                  className={connectedRight && 'rounded-r-none'}
                />
                {isFocused && (
                  <div className="absolute flex z-50 w-full py-2 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95">
                    {renderOptions(field)}
                  </div>
                )}
              </>
            </FormControl>
          </FormItem>
          {isValidElement(connectedRight) && (
            <div className="max-w-[200px]">
              {cloneElement(connectedRight, {
                className: cn(
                  connectedRight.props.className,
                  'rounded-l-none border-l-0'
                ),
              })}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default SelectableInputText;
