import RowActions from '@/containers/Settings/RowActions';

const actionsColumn = {
  accessorKey: 'actions',
  header: 'Actions',
  cell: (props) => <RowActions row={props.row} />,
};

const actionsColumnUser = {
  accessorKey: 'actions',
  header: 'Actions',
  cell: (props) => <RowActions row={props.row} url={'agents'} />,
};

export const shipperColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'number',
    header: 'Number',
  },
  {
    accessorKey: 'address',
    header: 'Address',
  },
  actionsColumn,
];

export const accesorialsColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'percentage',
    header: 'Percentage %',
  },
  actionsColumn,
];

export const unitsColumns = [
  {
    accessorKey: 'name',
    header: 'Units',
  },
  actionsColumn,
];

export const trailerTypesColumns = [
  {
    accessorKey: 'name',
    header: 'Trailer Types',
  },
  actionsColumn,
];

export const rateColumns = [
  {
    accessorKey: 'shipper',
    header: 'Shipper',
  },
  {
    accessorKey: 'consignee',
    header: 'Consignee',
  },
  {
    accessorKey: 'billTo',
    header: 'Bill to',
  },
  {
    accessorKey: 'rate',
    header: 'Rate',
  },
  {
    accessorKey: 'unit',
    header: 'Unit',
  },
  actionsColumn,
];

export const agentColumns = [
  {
    accessorKey: 'agentId',
    header: 'Agent ID',
  },
  {
    accessorKey: 'companyName',
    header: 'Company',
  },
  {
    accessorKey: 'status',
    header: 'Status',
  },
  actionsColumnUser,
]

export const userColumns = [
  {
    accessorKey: 'name',
    header: 'Name',
  },
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'role',
    header: 'Role',
  },
  actionsColumnUser,
]

export const rolesColumns = [
  {
    accessorKey: 'role',
    header: 'Role',
  },
  actionsColumnUser,
]
