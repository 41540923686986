import { groupDataByType } from '@/components/orders/functions';
import useApi from '@/hooks/useApi';
import { filterDataByType, isArray } from '@/lib/utils';

const { createContext, useContext, useEffect, useState } = require('react');

const SettingsContext = createContext();

export const useSettingsContext = () => {
  return useContext(SettingsContext);
};

export const SettingsProvider = ({ children }) => {
  const [data, setData] = useState([]);
  const [rates, setRates] = useState([]);
  const [options, setOptions] = useState({});
  const [activeTab, setActiveTab] = useState('shipper');
  const [filteredData, setFilteredData] = useState([]);
  const [isViewMode, setIsViewMode] = useState(false);
  const [selectedSetting, setSelectedSetting] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { makeApiCall } = useApi();

  const getOrderSettingsData = async () => {
    const response = await makeApiCall({
      url: '/order-settings',
      method: 'GET',
    });
    if (response?.status === 200) {
      setData(response.data);
      updateData(response.data);
      getOptions(response.data);
      getRates(response.data);
    }
  };

  const filterData = (inputValue) => {
    const updatedData = data.filter((item) => {
      for (const key in item) {
        if (
          key !== '_id' &&
          item[key] &&
          item[key].toString().toLowerCase().includes(inputValue.toLowerCase())
        ) {
          return true;
        }
      }
      return false;
    });
    setFilteredData(updatedData);
  };

  const getRates = (data) => {
    const { rate } = groupDataByType(data);
    setRates(rate);
  };

  const getOptions = (data) => {
    const optionsData = groupDataByType(data, true);
    setOptions(optionsData);
  };

  const updateData = (data) => {
    const updatedData = filterDataByType(data, activeTab);
    setFilteredData(updatedData);
  };

  useEffect(() => {
    isArray(data) && updateData(data);
  }, [activeTab, data]);

  useEffect(() => {
    getOrderSettingsData();
  }, []);

  const value = {
    rates,
    options,
    activeTab,
    isViewMode,
    filterData,
    isModalOpen,
    filteredData,
    setActiveTab,
    setIsViewMode,
    setIsModalOpen,
    selectedSetting,
    setSelectedSetting,
    getOrderSettingsData,
  };

  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  );
};
