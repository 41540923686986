import { useState } from 'react';

import Row from '@/components/ui/Row';
import SettingsTable from './SettingsTable';
import { SettingsForm } from './UserSettingsForm';
import { Input } from '@/components/ui/Input';
import { TabsContent } from '@/components/ui/tabs';
import SearchIcon from '@/components/icons/SearchIcon';

import { userTabsConfig } from '@/lib/settings/tabsConfig';
import { useUserSettingsContext } from '@/contexts/UserSettingsContext';

const UserSettingsTabsContent = () => {
  const [searchValue, setSearchValue] = useState('');
  const { activeTab, filteredData, filterData } = useUserSettingsContext();

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setSearchValue(inputValue);
    filterData(inputValue);
  };

  // Get the configuration and columns based on the activeTab value
  const { formConfig, columns, formTitle } =
    userTabsConfig[activeTab] || userTabsConfig.default;

  return (
    <TabsContent value={activeTab}>
      <Row className="justify-between mb-2">
        <Input
          value={searchValue}
          onChange={handleInputChange}
          placeholder="Search"
          className="border-gray-600"
          icon={<SearchIcon color="#514F4F" />}
        />
        <SettingsForm title={formTitle} formConfig={formConfig} />
      </Row>
      <SettingsTable columns={columns} data={filteredData} />
    </TabsContent>
  );
};

export default UserSettingsTabsContent;