import React from 'react';
import Header from '@/components/Header';

const Profile = () => {
  return (
    <>
      <Header title="Profile" />
    </>
  );
};

export { Profile };
