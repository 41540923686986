import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { Dashboard } from './containers/Dashboard/Dashboard';
import {
  Orders,
  CreateOrder,
  ViewOrder,
  EditOrder,
} from './containers/Orders/Orders';
import {
  Drivers,
  CreateDriver,
  EditDriver,
  ViewDriver,
} from './containers/Drivers/Drivers';
import Layout from './components/Layout';
import Login from './components/auth/Login';
import { Profile } from './containers/Profile/Profile';
import OrderSettings from './containers/Settings/OrderSettings';
import RecoverPassword from '@/components/auth/ResetPassword';
import LoginDrivers from './components/auth/LoginDrivers';
import { DashboardDrivers } from './containers/DashboardDrivers';
import UploadTicket from './components/drivers/UploadTicket';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { Agents } from './containers/Agents/Agents';
import UserSettings from './containers/Settings/UserSettings';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path="/" element={<Login />} />
      <Route path="password-recory" element={<RecoverPassword />} />
      <Route element={<ProtectedRoute route="/" />}>
        <Route path="orders" element={<Layout />}>
          <Route index element={<Orders />} />
          <Route path="new" element={<CreateOrder />} />
          <Route path="view" element={<ViewOrder />} />
          <Route path="edit" element={<EditOrder />} />
        </Route>
        <Route path="dashboard" element={<Layout />}>
          <Route index element={<Dashboard />} />
        </Route>
        <Route path="profile" element={<Layout />}>
          <Route index element={<Profile />} />
        </Route>
        <Route path="settings" element={<Layout />}>
          <Route path="orders" element={<OrderSettings />} />
          <Route path="users" element={<UserSettings />} />
        </Route>
        <Route path="drivers" element={<Layout />}>
          <Route index element={<Drivers />} />
          <Route path="new" element={<CreateDriver />} />
          <Route path="edit" element={<EditDriver />} />
          <Route path="view" element={<ViewDriver />} />
        </Route>
      </Route>
      <Route path="login-drivers" element={<LoginDrivers />} />
      <Route element={<ProtectedRoute route="/login-drivers" />}>
        <Route path="dashboard-drivers" element={<DashboardDrivers />} />
        <Route
          path="dashboard-drivers/upload-ticket"
          element={<UploadTicket />}
        />
      </Route>
      <Route path="add-agent-to-system" element={<Layout />}>
        <Route path="new" element={<Agents />} />
      </Route>
    </Route>
  )
);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
