import { Settings } from 'lucide-react';
import TabsContent from '@/containers/Settings/SettingsTabContent';
import { Tabs, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useSettingsContext } from '@/contexts/SettingsContext';

export default function OrderSettingsTabs({ tabs }) {
  const { activeTab, setActiveTab } = useSettingsContext();

  return (
    <Tabs
      value={activeTab}
      onValueChange={setActiveTab}
      defaultValue="shipper"
      className="w-full"
    >
      <TabsList className="gap-2">
        {tabs.map((tab, index) => (
          <TabsTrigger
            key={`SettingsTab${index}`}
            value={tab.value}
            className="bg-[#E4EDF4] min-w-[125px] py-1.5 border-b-0 data-[state=active]:border-b-2 data-[state=active]:bg-white data-[state=active]:text-black"
          >
            <Settings width={18} className="mr-1" />
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      <TabsContent />
    </Tabs>
  );
}
