import React, { useEffect, useState } from "react";
import OrderCardDriver from "./OrderCardDriver";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import TermsAndConditions from "./TermsAndConditions";

const DashboardDriversLayout = () => {

    const [searchParams] = useSearchParams();
    const baseURL = process.env.REACT_APP_ROOT_API;
    const [driver, setDriver] = useState();
    const [orders, setOrders] = useState();
    const [termsAgreed, setTermsAgreed] = useState(false);

    useEffect(() => {
        const getDriverData = async () => {
            await axios.get(`${baseURL}/drivers`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('accessToken')}`
                }
            }).then((response) =>{
                setDriver(response.data.drivers.filter(e => e._id === searchParams.get('id'))[0])
                setTermsAgreed(response.data.drivers.filter(e => e._id === searchParams.get('id'))[0]?.termsAgreed)
            })
            await axios.get(`${baseURL}/orders`, {
                headers: {
                    Authorization: `Bearer ${Cookies.get('accessToken')}`
                }
            }).then((response) => {
                setOrders(response.data);
            })
        }
        getDriverData();
     }, [])

    return (
        <>
            {termsAgreed != true && termsAgreed != false
            ? <div>
                <TermsAndConditions setTermsAgreed={setTermsAgreed} driver={driver}/>
            </div>
            : <div style={{height: '100vh', padding: '10px'}}>
                <h1 style={{fontWeight: 'bold', fontSize: '20px', marginBottom: '20px'}}>Upcoming Orders</h1>
                <div style={{display: 'flex', gap: '15px', flexWrap: 'nowrap', overflowX: 'auto', maxHeight: '93%', marginBottom: '30px'}}>
                    {
                        orders?.filter(e => e.mainTruckNumber === driver?.mainTruckNumber && e.driverId === driver?.driverId && e.status === 'Dispatched').length ?
                        orders?.filter(e => e.mainTruckNumber === driver?.mainTruckNumber && e.driverId === driver?.driverId && e.status === 'Dispatched')
                            .map((e, i) => <OrderCardDriver order={e} key={i}/>)
                        : <div style={{display:'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', width: '90%', border: '1px solid black', borderRadius: '5px'}}>
                            <p style={{fontSize: '24px', verticalAlign: 'center'}}>No orders dispatched</p>
                        </div>
                    }
                </div>
            </div>}
        </>
    );
};

export default DashboardDriversLayout;