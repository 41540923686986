import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApi from '@/hooks/useApi';
import { DeleteWarning } from '@/components/ui/alert-dialog';
import { Button } from '@/components/ui/Button';
import { EditIcon, Eye } from 'lucide-react';
import DeleteIcon from '@/components/icons/DeleteIcon';
import { useSettingsContext } from '@/contexts/SettingsContext';

export default function RowActions({ row, url='settings' }) {
  const _id = row.original._id;

  const navigate = useNavigate();
  const { loading, makeApiCall } = useApi();
  const [openWarning, setOpenWarning] = useState(false);
  const { setSelectedSetting, setIsModalOpen, setIsViewMode } =
    useSettingsContext();

  const handleDelete = () => setOpenWarning(true);

  const handleCancelDelete = () => setOpenWarning(false);

  const handleConfirmDelete = async () => {
    await makeApiCall({
      url: `/${url}`,
      method: 'DELETE',
      data: { _id },
    });
    navigate(0); // reload page
  };

  const handleView = () => {
    setSelectedSetting({ ...row.original });
    setIsViewMode(true);
    setIsModalOpen(true);
  };

  const handleEdit = () => {
    setSelectedSetting({ ...row.original });
    setIsViewMode(false);
    setIsModalOpen(true);
  };

  return (
    <>
      <DeleteWarning
        loading={loading}
        open={openWarning}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <div className="flex justify-center">
        <Button variant="link" size="icon" onClick={handleView}>
          <Eye color="#015CAA" size={20} />
        </Button>
        <Button variant="link" size="icon" onClick={handleEdit}>
          <EditIcon color="#015CAA" size={20} />
        </Button>
        <Button variant="link" size="icon" onClick={handleDelete}>
          <DeleteIcon color={'#015CAA'} />
        </Button>
      </div>
    </>
  );
}
