import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Button } from './Button';

const HeaderButton = ({ link, text, action, icon }) => {
  const renderButton = () => (
    <Button
      className="absolute z-20 right-4 mt-5 md:mt-0 md:right-0 md:relative"
      onClick={action}
    >
      {text}
      {icon && <span className="ml-2">{icon}</span>}
    </Button>
  );

  return (
    <>
      {text && (
        <div>
          {link ? <Link to={link}>{renderButton()}</Link> : renderButton()}
        </div>
      )}
      {!text && <div className="h-[40px]"></div>}
    </>
  );
};

HeaderButton.propTypes = {
  link: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.element,
};

export default HeaderButton;
