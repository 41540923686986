import React from 'react';
import Header from '@/components/Header';
import { ORDER_TABS } from '@/lib/constants';
import OrderSettingsTabs from '@/components/orderSettings/OrderSettingsTabs';

const Settings = () => {
  return (
    <>
      <Header title="Settings" />
      <OrderSettingsTabs tabs={ORDER_TABS} />
    </>
  );
};

export default Settings;
