import { Button } from '../ui/Button';
import CopyIcon from '../icons/CopyIcon';
import { DeleteWarning } from '../ui/alert-dialog';
import { useState } from 'react';
import useApi from '@/hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { Eye } from 'lucide-react';
import EditIcon from '../icons/EditIcon';
import { useAppContext } from '@/contexts/AppContext';
import DeleteIcon from '../icons/DeleteIcon';

export const RowActions = ({ row }) => {
  const _id = row.original._id;
  const status = row.getValue('status');

  const navigate = useNavigate();
  const { duplicateExistingOrder } = useAppContext();
  const [openModal, setOpenModal] = useState(false);

  const { loading, makeApiCall } = useApi();

  const handleDelete = () => setOpenModal(true);

  const handleCancelDelete = () => setOpenModal(false);

  const handleConfirmDelete = async () => {
    const response = await makeApiCall({
      url: '/orders/cancel',
      method: 'PATCH',
      data: { _id },
    });

    if (response?.status === 200) {
      navigate(0); // reload page
    }
  };

  const handleView = () => navigate(`/orders/view?id=${_id}`);

  const handleEdit = () => navigate(`/orders/edit?id=${_id}`);

  const handleDuplicate = () => {
    duplicateExistingOrder(row.original);
    navigate('/orders/new');
  };

  return (
    <>
      <DeleteWarning
        open={openModal}
        loading={loading}
        onCancel={handleCancelDelete}
        onConfirm={handleConfirmDelete}
      />
      <div className="flex justify-center">
        <Button variant="link" size="icon" onClick={handleView}>
          <Eye color="#015CAA" size={20} />
        </Button>
        <Button
          variant="link"
          size="icon"
          onClick={handleEdit}
          disabled={status === 'Cancelled'}
        >
          <EditIcon color={status === 'Cancelled' ? '#a4a4a4' : '#015CAA'} />
        </Button>
        <Button variant="link" size="icon" onClick={handleDuplicate}>
          <CopyIcon color={'#015CAA'} />
        </Button>
        <Button
          variant="link"
          size="icon"
          onClick={handleDelete}
          disabled={status === 'Finished'}
        >
          <DeleteIcon color={status === 'Finished' ? '#a4a4a4' : '#015CAA'} />
        </Button>
      </div>
    </>
  );
};
