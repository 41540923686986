import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Row from '../ui/Row';

import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Form,
  FormFieldInput,
  FormFieldWrapper,
  FormFieldDropdownWithSearch,
} from '../ui/form';
import { Button } from '../ui/Button';
import { TRAILER_TYPE_OPTIONS } from '@/lib/constants';
import useApi from '../../hooks/useApi';
import { AlertDialogWrapper } from '../ui/alert-dialog';
import formSchema from './formSchema';
import driverDefaultValues from './driverDefaultValues';
import { cn } from '@/lib/utils';
import Header from '../ui/FormHeader';
import Cookies from 'js-cookie';

const DriverForm = () => {
  const navigate = useNavigate();
  const { data, error, makeApiCall } = useApi();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const handleCancel = () => {
    // setIsModalOpen(true);
    navigate('/drivers');
  };

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: driverDefaultValues,
  });

  async function onSubmit(values) {
    setIsBlocking(false);
    let birthDateString = values.birthDate.split('-').reverse().join('');
    let password =
      birthDateString.slice(2, 4) +
      birthDateString.slice(0, 2) +
      birthDateString.slice(4, 8);
    // Do something with the form values.
    await makeApiCall({
      url: '/drivers',
      method: 'POST',
      data: {...values, agentId: Cookies.get('agentId') ? Cookies.get('agentId') : ""},
    })
      .then((response) => {
        if (response.data.message === 'Email for driver already registered') {
          alert('Cannot create driver. Email already registered.');
          form.setError('email');
          throw new Error('Email already used');
        }
        if (
          response.data.message === 'Phone number for driver already registered'
        ) {
          alert('Cannot create driver. Phone number already registered.');
          form.setError('phoneNumber');
          throw new Error('Phone number already used');
        }
        makeApiCall({
          url: '/auth/create-driver',
          method: 'POST',
          data: {
            email: values.email,
            password: 'A' + password,
          },
        });
      })
      .then(() => navigate('/drivers'))
      .catch((e) => console.log(e));
  }

  // Effect to handle general errors
  useEffect(() => {
    if (data) {
      console.log(data);
    }
    if (error) {
      console.log(error);
    }
  }, [data, error]);

  const onDateSelected = (date) => {
    form.setValue('birthDate', date);
  };

  return (
    <>
      <AlertDialogWrapper
        isBlocking={isBlocking}
        title="Cancel Driver"
        description="Are you sure you want to cancel the driver creation?"
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="space-y-4 pb-20 md:pb-0"
        >
          <Header>CARRIER INFORMATION</Header>
          <Row gap={5} className="md:w-60">
            <FormFieldInput
              label="Carrier Code (optional)"
              name="carrierCode"
              placeholder="Type Here"
              control={form.control}
            />
          </Row>
          <div
            style={{
              display: form.getValues().carrierCode !== '' ? '' : 'none',
            }}
          >
            <Row gap={5}>
              <FormFieldInput
                label="Carrier Company Name*"
                name="carrierCompanyName"
                placeholder="Type Here"
                control={form.control}
              />
              <FormFieldInput
                label="Contact Name*"
                name="contactName"
                placeholder="Type Here"
                control={form.control}
              />
            </Row>
            <Row gap={5}>
              <FormFieldInput
                label="Contact Email*"
                name="contactEmail"
                placeholder="Type Here"
                control={form.control}
              />
              <FormFieldInput
                label="Contact Number*"
                name="contactNumber"
                placeholder="Type Here"
                control={form.control}
              />
              <FormFieldInput
                label="DOT Number*"
                name="dotNumber"
                placeholder="Type Here"
                control={form.control}
              />
            </Row>
          </div>
          <br />
          <hr />
          <Header showRequired>GENERAL INFORMATION</Header>
          <Row gap={5}>
            <FormFieldInput
              label={`Driver's ID${
                form.getValues().carrierCode === '' ? '*' : ' (optional)'
              }`}
              name="driverId"
              placeholder="Type number"
              control={form.control}
            />
            <FormFieldInput
              label="Driver's Name*"
              name="driverName"
              placeholder="Choose a driver"
              control={form.control}
            />
            <FormFieldInput
              label="Driver's/Carrier's Percentage*"
              name="driverOrCarrierPercentage"
              placeholder="0.00%"
              control={form.control}
            />
          </Row>
          <Row gap={5}>
            <FormFieldInput
              label="Main Truck Number*"
              name="mainTruckNumber"
              placeholder="Type Here"
              control={form.control}
            />
            <FormFieldInput
              label="Truck Number 2 (optional)"
              name="truckNumber2"
              placeholder="Type Here"
              control={form.control}
            />
            <FormFieldInput
              label="Truck Number 3 (optional)"
              name="truckNumber3"
              placeholder="Type Here"
              control={form.control}
            />
          </Row>
          <Row gap={5}>
            <FormFieldDropdownWithSearch
              label="Main Trailer Type*"
              name="mainTrailerType"
              placeholder="Choose Trailer Type"
              control={form.control}
              options={TRAILER_TYPE_OPTIONS}
              connectedRight={
                <FormFieldInput
                  label="Number*"
                  name="numberMainTrailerType"
                  placeholder="000000"
                  control={form.control}
                  className="w-20"
                />
              }
            />
            <FormFieldDropdownWithSearch
              label="Trailer Type 2 (optional)"
              name="trailerType2"
              placeholder="Choose Trailer Type"
              control={form.control}
              options={TRAILER_TYPE_OPTIONS}
              connectedRight={
                <FormFieldInput
                  label="Number"
                  name="numberTrailerType2"
                  placeholder="000000"
                  control={form.control}
                  className="w-20"
                />
              }
            />
            <FormFieldDropdownWithSearch
              label="Trailer Type 3 (optional)"
              name="trailerType3"
              placeholder="Choose Trailer Type"
              control={form.control}
              options={TRAILER_TYPE_OPTIONS}
              connectedRight={
                <FormFieldInput
                  label="Number"
                  name="numberTrailerType3"
                  placeholder="000000"
                  control={form.control}
                  className="w-20"
                />
              }
            />
          </Row>
          <Row gap={5}>
            <FormFieldInput
              label="Company / Owner*"
              name="companyOrOwner"
              placeholder="Type Here"
              control={form.control}
            />
            <FormFieldInput
              label="Phone Number*"
              name="phoneNumber"
              placeholder="10 digit number"
              control={form.control}
            />
            <FormFieldInput
              label={`Email${
                form.getValues().carrierCode === '' ? '*' : ' (optional)'
              }`}
              name="email"
              placeholder="e.g. driver@gmail.com"
              control={form.control}
            />
          </Row>
          <Row gap={5}>
            <FormFieldWrapper
              label="Birth Date*"
              name="birthDate"
              control={form.control}
            >
              <input
                type="date"
                id="birthDate"
                name="birthDate"
                className={cn(
                  'block w-full md:w-1/3 border rounded-md p-2 text-sm',
                  form.getValues().birthDate === '' ? 'text-gray-400' : 'black',
                  isSubmitted && form.getValues().birthDate === ''
                    ? 'border-red-500'
                    : 'border-blue-600'
                )}
                onChange={(e) => {
                  onDateSelected(e.target.value);
                  setIsSubmitted(true);
                  form.trigger('birthDate');
                }}
              />
            </FormFieldWrapper>
          </Row>
          <br />
          <hr />
          <Header showRequired>PARKING LOCATION</Header>
          <Row gap={5}>
            <FormFieldInput
              label="State*"
              name="state"
              placeholder="Select state"
              control={form.control}
            />
            <FormFieldInput
              label="City*"
              name="city"
              placeholder={
                form.getValues().state ? 'Select city' : 'Select state first'
              }
              control={form.control}
            />
            <FormFieldInput
              label={`Zip Code${
                form.getValues().carrierCode === '' ? '*' : ' (optional)'
              }`}
              name="zipCode"
              placeholder="0000000"
              control={form.control}
            />
            <FormFieldInput
              label={`Address${
                form.getValues().carrierCode === '' ? '*' : ' (optional)'
              }`}
              name="address"
              placeholder="Type Here"
              control={form.control}
            />
          </Row>
          <br />
          <div
            className={cn(
              'gap-3 p-5 w-full flex fixed bottom-0 left-0 bg-white shadow-[0_0_12px__rgba(0,0,0,0.2)]',
              'md:relative md:float-right md:w-auto md:p-0 md:shadow-none'
            )}
          >
            <Button
              type="button"
              variant="outline"
              onClick={handleCancel}
              className="w-full md:w-auto"
            >
              CANCEL
            </Button>
            <Button
              type="submit"
              onClick={() => setIsSubmitted(true)}
              className="w-full md:w-auto"
            >
              CREATE
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};

export default DriverForm;
