import {
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import { Table } from '../ui/table';
import { commonColumns as columns } from './columns';
import Pagination from '../ui/pagination';
import { useState } from 'react';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import CustomTableBody from '../ui/TableBody';
import CustomTableHeader from '../ui/TableHeader';

export const OrderDispatcherTable = ({ data }) => {
  const [sorting, setSorting] = useState([]);
  const [pageSize, setPageSize] = useState(15);
  const [pageIndex, setPageIndex] = useState(0);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      pagination: {
        sorting,
        pageSize,
        pageIndex,
      },
    },
  });

  return (
    <>
      <ScrollArea className="h-[300px] max-w-5xl">
        <Table>
          <CustomTableHeader headerGroups={table.getHeaderGroups()} />
          <CustomTableBody columns={columns} rows={table.getRowModel().rows} />
        </Table>
        <ScrollBar orientation="horizontal" transparent />
      </ScrollArea>
      <Pagination
        data={data}
        table={table}
        pageSize={pageSize}
        pageIndex={pageIndex}
        setPageSize={setPageSize}
        setPageIndex={setPageIndex}
        className="md:w-full md:left-0 relative"
      />
    </>
  );
};
