import { isArray } from '@/lib/utils';

const { useSettingsContext } = require('@/contexts/SettingsContext');

const useRateSelection = (form) => {
  const { rates } = useSettingsContext();

  const updateRate = () => {
    if (!isArray(rates)) {
      return;
    }

    const { shipper, billTo, consignee } = form.getValues();

    // Find the rate with the same shipper, billTo, and consignee fields
    const existingRate = rates.find(
      (rate) =>
        rate.shipper === shipper &&
        rate.billTo === billTo &&
        rate.consignee === consignee
    );

    if (existingRate) {
      // Found a matching rate, perform your update logic here
      console.log('Found existing rate:', existingRate);
      form.setValue('rate', existingRate.rate);
      form.setValue('rateCurrency', existingRate.unit);
    }
  };

  return {
    updateRate,
  };
};

export default useRateSelection;
