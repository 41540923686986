'use client';
import React, { useState } from 'react';
import {
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';

import { Table } from '../ui/table';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';
import DriverFilters from './DriverFilters';
import Pagination from '../ui/pagination';
import TableHeader from '../ui/TableHeader';
import TableBody from '../ui/TableBody';
import { multiSelectFilter } from '@/lib/utils';

export function DataTable({ columns, data }) {
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageIndex, setPageIndex] = useState(0);
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFilteredRowModel: getFilteredRowModel(),
    onRowSelectionChange: setRowSelection,
    filterFns: {
      multiSelectFilter,
    },
    state: {
      sorting,
      columnFilters,
      pagination: {
        pageIndex,
        pageSize,
      },
      rowSelection,
    },
  });

  return (
    <>
      <ScrollArea>
        <DriverFilters data={data} table={table} />
        <ScrollBar orientation="horizontal" transparent />
      </ScrollArea>
      <Table className="mb-16">
        <TableHeader headerGroups={table.getHeaderGroups()} />
        <TableBody columns={columns} rows={table.getRowModel().rows} />
      </Table>
      <Pagination
        data={data}
        table={table}
        pageSize={pageSize}
        pageIndex={pageIndex}
        setPageSize={setPageSize}
        setPageIndex={setPageIndex}
      />
    </>
  );
}
