"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/Button"
import { Calendar } from "@/components/ui/calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover"

export default function DatePickerWithRange({
  column
}) {
  const [date, setDate] = React.useState(null);

  const handleReset = () => {
    setDate(null)
    column?.setFilterValue()
  }

  const handleSelect = (newDate) => {
    setDate(newDate)
    column?.setFilterValue(newDate)
  }

  const handleButtonClick = () => {
    if (!date) {
      const currentDate = new Date();
      setDate({
        from: currentDate,
      });
    }
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          id="date"
          size='filter'
          variant='filter'
          className={cn(
            "text-left text-sm",
            !date && "text-[#D3D3D3]"
          )}
          onClick={handleButtonClick}
        >
          {date?.from ? (
            date.to ? (
              <>
                {format(date.from, "LLL dd, y")} -{" "}
                {format(date.to, "LLL dd, y")}
              </>
            ) : (
              format(date.from, "LLL dd, y")
            )
          ) : (
            <span>Pick-up Date</span>
          )}
          <CalendarIcon className="ml-2 h-4 w-4" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={handleSelect}
          numberOfMonths={2}
        />
        <div className="flex justify-end">
          <Button variant='link' onClick={handleReset}>CLEAR</Button>
        </div>
      </PopoverContent>
    </Popover>
  );
}