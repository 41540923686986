const CustomDashedLine = ({ width, space, height, className }) => {
  const lineStyle = {
    width: '100%',
    height: height || '1px',
    marginLeft: '-5px',
    backgroundImage: `repeating-linear-gradient(to right, transparent, transparent ${space}px, #A8A7A7 ${space}px, #A8A7A7 ${
      space + width
    }px)`,
  };

  return <div className={className} style={lineStyle}></div>;
};

export default CustomDashedLine;
