import React, { useEffect } from 'react';
import { DataTable } from '@/components/drivers/DataTable';
import DriverForm from '@/components/drivers/DriverForm';
import DriverFormEdit from '@/components/drivers/DriverFormEdit';
import DriverFormView from '@/components/drivers/DriverFormView';
import useApi from '@/hooks/useApi';
import Header from '@/components/Header';
import useIsMobile from '@/hooks/useIsMobile';
import { desktopColumns, mobileColumns } from '@/components/drivers/columns';
import Cookies from 'js-cookie';

const Drivers = () => {
  const isMobile = useIsMobile();
  const { data, makeApiCall } = useApi();

  const getDrivers = async () => {
    await makeApiCall({
      url: '/drivers/filteredList',
      method: 'POST',
      data: {
        agentId: Cookies.get('agentId'),
      }
    });
  };

  useEffect(() => {
    getDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortedData = data
    ? data.drivers
        .sort((a, b) => Date.parse(b.creationDate) - Date.parse(a.creationDate))
    : [];

  const columns = isMobile ? mobileColumns : desktopColumns;

  return (
    <>
      <Header
        title="Drivers"
        primaryAction={{ text: 'NEW DRIVER', link: 'new' }}
      />
      <DataTable data={sortedData} columns={columns} />
    </>
  );
};

const CreateDriver = () => {
  return (
    <>
      <Header
        title="New Driver"
        previousRoute="/drivers"
        primaryAction={null}
      />
      <DriverForm />
    </>
  );
};

const EditDriver = () => {
  return (
    <>
      <Header
        title="Edit Driver"
        previousRoute="/drivers"
        primaryAction={null}
      />
      <DriverFormEdit />
    </>
  );
};

const ViewDriver = () => {
  return (
    <>
      <Header
        title="View Driver"
        previousRoute="/drivers"
        primaryAction={null}
      />
      <DriverFormView />
    </>
  );
};

export { Drivers, CreateDriver, EditDriver, ViewDriver };
