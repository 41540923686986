import React from 'react';
import Header from '@/components/Header';

const Dashboard = () => {
  return (
    <>
      <Header title="Dashboard" />
    </>
  );
};

export { Dashboard };
