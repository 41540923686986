export const STATUS_OPTIONS = [
  'Created',
  'Assigned',
  'Dispatched',
  'Finished',
  'Cancelled',
];

export const NOT_FOUND_TEXT = 'No items found';

export const DEFAULT_ORDER_STATUS = ['Created', 'Dispatched'];

export const CURRENCY_OPTIONS = ['USD', 'CAD', 'MXN'];

export const EXAMPLE_OPTIONS = ['Option 1', 'Option 2', 'Option 3'];

export const TRAILER_TYPE_OPTIONS = [
  'Belly Dump',
  'End Dump',
  'Flatbed',
  'Cement',
  'Tanker',
  'Tandem',
  'Dump Truck with Pup',
  'Tri Axle Dump',
  'Quad Dump',
  'Super Dump',
  'Side Dump',
  'Super Tandem',
];

export const UNITS_OPTIONS = ['Ton', 'Hour', 'Load', 'Cubic Yard'];

export const STATUS_DRIVERS = [
  { status: 'Available' },
  { status: 'Out of Service' },
];

export const MATERIAL_OPTIONS = [
  '1/2IN X 3/8IN',
  '30/50 Industrial Sand',
  '421 GR4',
  'Asphalt',
  'Ball Clay',
  'Basalt Rock',
  'Beach Pebble',
  'Brick Sand',
  'Brush',
  'Cement',
  'Cement Treated Sand',
  'Common Fill Dirt',
  'Compost Soil Mixes',
  'Concrete Sand',
  'Concrete WITH OUT REBAR',
  'Concrete WITH REBAR',
  'Construction Trash',
  'Crushed Concrete Rock',
  'Crushed Granite',
  'Crushed Limestone Rock',
  'Crushed Rock',
  'Crushed Stone',
  'Cushion Sand',
  'Decomposed Granite',
  'Decorative Landscape Rock',
  'DF Blend',
  'Fill Dirt',
  'Fine Sand',
  'Fly Ash',
  'General Fill',
  'GR 5',
  'Grass STRIPPINGS',
  'Gravel',
  'HM Screenings',
  'Hot Mix',
  'Industrial Sand',
  'Landscape Rock',
  'Man Sand',
  'Mason Sand',
  'Millings',
  'Mulch',
  'Pea Gravel',
  'Processed RAP',
  'RAP Milled',
  'RIP RAP',
  'River Rock',
  'Road Base',
  'Rock',
  'Rock - 6 INCH MINUS',
  'Rock - 6 INCH PLUS ROCK',
  'Rock Millings',
  'Sand',
  'Sandy Loam',
  'Screened Topsoil',
  'Screenings',
  'Select Fill',
  'Select Fill Dirt',
  'TopSoil',
  'Topsoil',
  'Tress',
  'Washed Gravel',
  'Washed Poteet Sand',
  'Washed Sand',
  'White Brick Sand',
];

export const ACCESORIALS_OPTIONS = [
  'DEADHEAD',
  'BACKHAUL',
  'DETENTION',
  'DETENTION-LOADING',
  'DETENTION-UNLOADING',
  'DETENTION WITHOUT POWER',
  'FUEL SURCHARGE',
  'WAIT TIME',
  'STORAGE',
];

export const HOUR_OPTIONS = Array.from({ length: 24 }, (_, i) => {
  const formattedHour = i < 10 ? `0${i}` : `${i}`;
  return {
    value: formattedHour,
    label: formattedHour,
  };
});

export const MINUTE_OPTIONS = Array.from({ length: 60 }, (_, i) => {
  const formattedMinute = i < 10 ? `0${i}` : `${i}`;
  return {
    value: formattedMinute,
    label: formattedMinute,
  };
});

export const ORDER_TABS = [
  { value: 'shipper', label: 'Shipper' },
  { value: 'consignee', label: 'Consignee' },
  { value: 'billTo', label: 'Bill To' },
  { value: 'rate', label: 'Rate' },
  { value: 'accesorials', label: 'Accesorials' },
  { value: 'unit', label: 'Units' },
  { value: 'trailerTypes', label: 'Trailer Types' },
];

export const USER_TABS = [
  { value: 'agent', label: 'Agent' },
  { value: 'user', label: 'User' },
  { value: 'roles', label: 'Roles' },
];
