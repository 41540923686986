import React, { createContext, useContext, useState } from 'react';
import { initialOrderState } from '../components/orders/formConfig';

const AppContext = createContext();

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppProvider = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [forms, setForms] = useState([initialOrderState]);
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const addForm = (newData) => {
    setForms((prevForms) => [...prevForms, newData]);
  };

  const duplicateForm = () => {
    setForms((prevForms) => {
      const originalForm = prevForms[activeTabIndex];

      // Exclude properties from the original form
      const duplicatedForm = Object.assign({}, originalForm, {
        files: [],
        quantity: '',
        quantityUnit: 'Ton',
        ticketNumber: '',
      });

      return [...prevForms, duplicatedForm];
    });

    setActiveTabIndex((prevIndex) => prevIndex + 1);
  };

  const duplicateExistingOrder = (data) => {
    // Extracting the fields to be removed
    const { files, quantity, quantityUnit, ticketNumber, ...newData } = data;

    resetForms();
    addForm({
      ...newData,
      id: null,
      _id: null,
    });
  };

  const removeForm = (index) => {
    setForms((prevForms) => prevForms.filter((_, i) => i !== index));
    // Determine the new active tab index
    let newActiveTabIndex;
    if (activeTabIndex === forms.length - 1) {
      // If the removed form was the last one, set the active tab to the previous form
      newActiveTabIndex = Math.max(0, forms.length - 2);
    } else {
      // Otherwise, set the active tab to the next form
      newActiveTabIndex = activeTabIndex;
    }

    setActiveTabIndex(newActiveTabIndex);
  };

  const updateFormData = (newData, index) => {
    setForms((prevForms) => {
      const updatedForms = [...prevForms];
      updatedForms[index] = newData;
      return updatedForms;
    });
  };

  const resetForms = () => {
    setForms([]);
    setActiveTabIndex(0);
  };

  const currentForm = forms[activeTabIndex];

  const value = {
    forms,
    addForm,
    activeTabIndex,
    setActiveTabIndex,
    currentForm,
    duplicateForm,
    removeForm,
    updateFormData,
    resetForms,
    isMenuOpen,
    setIsMenuOpen,
    duplicateExistingOrder,
    // Add any other state or functions you want to share
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
