import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { MapPin } from 'lucide-react';
import {
  Form,
  FormFieldDate,
  FormFieldDropdown,
  FormFieldDropdownWithSearch,
  FormFieldInput,
  FormFieldWrapper,
} from '../ui/form';
import Row from '../ui/Row';
import { Button } from '../ui/Button';
import TimePicker from '../ui/TimePicker';
import { Textarea } from '../ui/textarea';
import Accessorials from './Accesorials';
import {
  CURRENCY_OPTIONS,
  MATERIAL_OPTIONS,
  UNITS_OPTIONS,
} from '@/lib/constants';
import FileUpload from '../ui/FileUpload';
import Divider from '../ui/divider';
import useApi from '@/hooks/useApi';
import { AlertDialogWrapper, AlertDialogWrapper2 } from '../ui/alert-dialog';
import { calculateOrderTotal } from './functions';
import { useAppContext } from '@/contexts/AppContext';
import useDriverSelection from '@/hooks/useDriverSelection';
import { cn, formatCurrency } from '@/lib/utils';
import DollarSignIcon from '../icons/DollarSign';
import { formSchema, initialOrderState } from './formConfig';
import SelectableInputText from '../ui/SelectableInputText';
import Header from '../ui/FormHeader';
import { useSettingsContext } from '@/contexts/SettingsContext';
import useRateSelection from '@/hooks/useRateSelection';

const OrderForm = ({ initialData, viewMode }) => {
  const navigate = useNavigate();
  const { forms, updateFormData, activeTabIndex } = useAppContext();
  const { options } = useSettingsContext();

  const isViewMode = viewMode === 'view';
  // API and Modal State
  const { loading, statusCode, makeApiCall } = useApi();
  const [isBlocking, setIsBlocking] = useState(viewMode === 'create');
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: initialOrderState,
    values: initialData ?? initialOrderState,
  });

  const { updateRate } = useRateSelection(form);

  const {
    driverIdOptions,
    driverNameOptions,
    truckNumberOptions,
    handleCarrierCompanyName,
    handleSelectDriver,
  } = useDriverSelection(form);

  const handleBackButton = (e) => {
    e.preventDefault();
    setIsModalOpen2(true);
    setIsBlocking(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    if (initialData?._id) {
      // Edit order
      await makeApiCall({
        url: '/orders/edit',
        method: 'PUT',
        data: form.getValues(),
      });
    } else {
      // Create order
      await makeApiCall({
        url: '/orders',
        method: 'POST',
        data: forms,
      });
    }
    setIsBlocking(false);
  };

  useEffect(() => {
    // Go to table after order creation
    if (statusCode === 201 || statusCode === 204) {
      navigate('/orders');
    }
  }, [statusCode, navigate]);

  const calculateTotal = () => calculateOrderTotal(form);

  useEffect(() => {
    const watchCallback = (value) => updateFormData(value, activeTabIndex);

    // Register the watcher
    const watcher = form.watch(watchCallback);

    // Return a cleanup function to unregister the watcher when the component is unmounted
    return () => {
      // Unregister the watcher
      watcher.unsubscribe();
    };
  }, [form, updateFormData, activeTabIndex]);

  const handleFinishOrder = async () => {
    setIsBlocking(false);
    const response = await makeApiCall({
      url: '/orders/finish',
      method: 'PUT',
      data: forms,
    });
    if (response?.status === 204) navigate('/orders');
    else setIsBlocking(true);
  };

  const navigateToOrders = () => {
    navigate('/orders');
  };

  const handleFormat = (e) => {
    form.setValue('rate', formatCurrency(e.target.value));
  };

  return (
    <>
      <AlertDialogWrapper
        title="Cancel Order"
        isBlocking={isBlocking}
        description="Are you sure you want to cancel the order creation?"
      />
      <AlertDialogWrapper2
        open={isModalOpen2}
        title="Cancel Order"
        setOpen={setIsModalOpen2}
        onConfirm={navigateToOrders}
        description="Are you sure you want to cancel editing the order?"
      />
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(handleFinishOrder)}
          className="space-y-4 pb-20 md:pb-0"
        >
          <Header>GENERAL INFORMATION</Header>
          <Row>
            <FormFieldInput
              disabled={isViewMode}
              label="ProNumber"
              name="proNumber"
              placeholder="Type number"
              control={form.control}
            />
            <FormFieldInput
              disabled={isViewMode}
              label="Carrier Company Name"
              name="carrierCompanyName"
              placeholder="000000"
              onChange={handleCarrierCompanyName}
              control={form.control}
            />
            <FormFieldDropdownWithSearch
              label="Truck Number"
              name="mainTruckNumber"
              placeholder="Choose a number"
              options={truckNumberOptions}
              control={form.control}
              disabled={isViewMode}
              onChange={handleSelectDriver}
            />
          </Row>
          <Row>
            <FormFieldDropdownWithSearch
              label="Driver ID"
              name="driverId"
              placeholder="Choose a Driver ID"
              options={driverIdOptions}
              control={form.control}
              disabled={isViewMode}
              onChange={handleSelectDriver}
            />
            <FormFieldDropdownWithSearch
              label="Driver's Name"
              name="driverName"
              placeholder="Choose a Driver"
              options={driverNameOptions}
              control={form.control}
              disabled={isViewMode}
              onChange={handleSelectDriver}
            />
          </Row>
          <Row>
            <SelectableInputText
              label="Shipper"
              name="shipper"
              options={options['shipper'] || []}
              placeholder={'e.g. Texas Materials New Braunfels'}
              disabled={isViewMode}
              control={form.control}
              onChange={updateRate}
              connectedRight={
                <FormFieldInput
                  name="shipperNumber"
                  placeholder="000000"
                  disabled={isViewMode}
                  control={form.control}
                />
              }
            />
            <SelectableInputText
              label="Consignee"
              name="consignee"
              options={options['consignee'] || []}
              placeholder="e.g. Texas Materials New Braunfels"
              control={form.control}
              disabled={isViewMode}
              onChange={updateRate}
              connectedRight={
                <FormFieldInput
                  name="consigneeNumber"
                  placeholder="000000"
                  disabled={isViewMode}
                  control={form.control}
                />
              }
            />
          </Row>
          <Row>
            <SelectableInputText
              label="Bill To"
              name="billTo"
              options={options['billTo'] || []}
              placeholder="e.g. Texas Materials New Braunfels"
              control={form.control}
              disabled={isViewMode}
              onChange={updateRate}
              connectedRight={
                <FormFieldInput
                  name="billToNumber"
                  placeholder="000000"
                  disabled={isViewMode}
                  control={form.control}
                />
              }
            />
            <Row>
              <FormFieldInput
                label="Division"
                name="division"
                placeholder="Type here"
                disabled={isViewMode}
                control={form.control}
              />
              <FormFieldInput
                label="Job Name"
                name="jobName"
                placeholder="Type here"
                disabled={isViewMode}
                control={form.control}
              />
            </Row>
          </Row>
          <Divider />
          <Header>DELIVERY INFORMATION</Header>
          <Row>
            <FormFieldDate
              label="Pick-Up Date"
              name="pickupDate"
              control={form.control}
              disabled={isViewMode}
            />
            <FormFieldWrapper
              label="Pick-Up Time"
              name="pickupTime"
              control={form.control}
              disabled={isViewMode}
            >
              <TimePicker />
            </FormFieldWrapper>
            <FormFieldDate
              label="Drop-Off Date"
              name="dropoffDate"
              control={form.control}
              disabled={isViewMode}
            />
            <FormFieldWrapper
              label="Drop-Off Time"
              name="dropoffTime"
              control={form.control}
              disabled={isViewMode}
            >
              <TimePicker />
            </FormFieldWrapper>
          </Row>
          <Row>
            <FormFieldInput
              label="Pick-Up Location"
              name="pickupLocation"
              placeholder="e.g. San José"
              disabled={isViewMode}
              control={form.control}
              icon={<MapPin width={18} />}
            />
            <FormFieldInput
              label="Drop-Off Location"
              name="dropoffLocation"
              placeholder="e.g. San José"
              disabled={isViewMode}
              control={form.control}
              icon={<MapPin width={18} />}
            />
          </Row>
          <Row>
            <FormFieldInput
              label="Order Number"
              name="orderNumber"
              placeholder="Type here"
              disabled={isViewMode}
              control={form.control}
            />
            <FormFieldInput
              label="PO Number"
              name="poNumber"
              placeholder="0000000"
              disabled={isViewMode}
              control={form.control}
            />
            <FormFieldInput
              label="Dispatch Number"
              name="dispatchNumber"
              placeholder="0000000"
              disabled={isViewMode}
              control={form.control}
            />
          </Row>
          <FormFieldWrapper
            label="Specified instructions / Notes"
            name="notes"
            control={form.control}
            disabled={isViewMode}
          >
            <Textarea placeholder="Type here." />
          </FormFieldWrapper>
          <Row>
            <SelectableInputText
              label="Material"
              name="material"
              options={MATERIAL_OPTIONS}
              placeholder="Select Material"
              disabled={isViewMode}
              control={form.control}
            />
            <Row>
              <FormFieldInput
                label="Rate"
                name="rate"
                type="money"
                placeholder="0000000"
                onBlur={handleFormat}
                control={form.control}
                disabled={isViewMode}
                onChange={calculateTotal}
                icon={<DollarSignIcon />}
                connectedRight={
                  <FormFieldDropdown
                    isConnected
                    placeholder="USD"
                    options={CURRENCY_OPTIONS}
                    name="rateCurrency"
                    disabled={isViewMode}
                    control={form.control}
                  />
                }
              />
              <FormFieldInput
                label="Quantity"
                name="quantity"
                inputMode="numeric"
                placeholder="0000000"
                control={form.control}
                disabled={isViewMode}
                onChange={calculateTotal}
                connectedRight={
                  <FormFieldDropdown
                    isConnected
                    placeholder="Ton"
                    options={UNITS_OPTIONS}
                    name="quantityUnit"
                    disabled={isViewMode}
                    control={form.control}
                  />
                }
              />
            </Row>
          </Row>
          <div className="md:grid grid-cols-4 gap-5">
            <div className="col-start-4">
              <FormFieldInput
                disabled
                type="money"
                label="Total"
                name="total"
                placeholder="0.00"
                control={form.control}
              />
            </div>
          </div>
          <Accessorials form={form} isViewMode={isViewMode} />
          <Divider />
          <Header>AGENT COMMISSION</Header>
          <Row>
            <FormFieldInput
              label="Commission Agent 1"
              name="commissionAgent1"
              placeholder="Type here"
              disabled={isViewMode}
              control={form.control}
            />
            <FormFieldInput
              label="Percentage for Agent 1"
              name="agentPercentage1"
              placeholder="000000"
              suffix="%"
              disabled={isViewMode}
              control={form.control}
            />
            <FormFieldInput
              label="Commission Agent 2"
              name="commissionAgent2"
              placeholder="Type here"
              disabled={isViewMode}
              control={form.control}
            />
            <FormFieldInput
              label="Percentage for Agent 2"
              name="agentPercentage2"
              placeholder="000000"
              suffix="%"
              disabled={isViewMode}
              control={form.control}
            />
          </Row>
          <Divider />
          <Header>EVIDENCE</Header>
          <FileUpload control={form.control} isViewMode={isViewMode} />
          <div
            className={cn(
              'gap-3 p-5 w-full justify-center flex fixed bottom-0 left-0 bg-white shadow-[0_0_12px__rgba(0,0,0,0.2)]',
              'md:relative md:float-right md:w-auto md:p-0 md:shadow-none'
            )}
          >
            {isViewMode ? (
              <Button onClick={navigateToOrders}>BACK</Button>
            ) : (
              <>
                <Button
                  variant={'link'}
                  type="submit"
                  className={!form.formState.isValid && 'text-gray-300'}
                  loading={loading}
                >
                  FINISH ORDER
                </Button>
                <Button variant="outline" onClick={handleBackButton}>
                  BACK
                </Button>
                <Button
                  onClick={handleSave}
                  disabled={loading}
                  loading={loading}
                >
                  {initialData?._id ? 'SAVE' : 'CREATE'}
                  {forms.length > 1 ? (
                    <span className="ml-1">({forms.length})</span>
                  ) : null}
                </Button>
              </>
            )}
          </div>
        </form>
      </Form>
    </>
  );
};

export default OrderForm;
