import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

const Row = ({ className, gap, children }) => {
  // Define the base classes for the row
  const baseClasses = `md:flex md:space-y-0 space-y-5 justify-between items-start w-full gap-${
    gap ?? 5
  }`;

  // Combine base classes with the custom classes and gap class provided through props
  const rowClasses = cn(baseClasses, className);

  return <div className={rowClasses}>{children}</div>;
};

Row.propTypes = {
  className: PropTypes.string,
  gap: PropTypes.number,
  children: PropTypes.node.isRequired,
};

export default Row;
