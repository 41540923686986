import React from 'react';
import Sidebar from './Sidebar';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './auth/AuthProvider';
import { useAppContext } from '@/contexts/AppContext';
import { cn } from '@/lib/utils';

const Layout = () => {
  const { isAuthenticated } = useAuth();
  const { isMenuOpen, setIsMenuOpen } = useAppContext();

  const hideMenu = () => setIsMenuOpen(false);

  return isAuthenticated ? (
    <div className="flex h-screen p-0 md:py-6 md:pl-10">
      <Sidebar />

      {/* Content */}
      <div className="h-full flex-1 p-5 md:pr-10 w-full overflow-y-auto relative">
        {/* Overlay */}
        {isMenuOpen && (
          <div
            className={cn(
              'fixed block top-0 left-0 w-full h-full bg-black opacity-50 z-40',
              'md:hidden'
            )}
            onClick={hideMenu}
          />
        )}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to="/" replace state={{ from: window.location.pathname }} />
  );
};

export default Layout;
