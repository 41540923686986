import { useAppContext } from '@/contexts/AppContext';
import { Tabs, TabsList, TabsTrigger } from '../ui/tabs';
import { X } from 'lucide-react';
import { ScrollArea, ScrollBar } from '../ui/scroll-area';

const OrderTabs = () => {
  const { forms, activeTabIndex, setActiveTabIndex, removeForm } =
    useAppContext();

  if (forms.length <= 1) return;

  return (
    <Tabs value={activeTabIndex} onValueChange={setActiveTabIndex}>
      <ScrollArea className="w-full mb-2 pr-[150px] md:w-[81vw] md:pr-0">
        <TabsList className="h-0">
          {forms.map((_, idx) => (
            <TabsTrigger
              value={idx}
              key={`OrderTab_${idx}`}
              className="inline-flex"
            >
              Order {idx + 1}
              <X
                size={17}
                className="ml-3"
                onClick={(e) => {
                  e.stopPropagation();
                  removeForm(idx);
                }}
              />
            </TabsTrigger>
          ))}
        </TabsList>
        <ScrollBar orientation="horizontal" />
      </ScrollArea>
    </Tabs>
  );
};

export default OrderTabs;
