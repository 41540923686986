import React, { useCallback, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { desktopColumns, mobileColumns } from '@/components/orders/columns';
import { DataTable } from '@/components/orders/DataTable';
import Header from '@/components/Header';
import OrderForm from '@/components/orders/OrderForm';
import useApi from '@/hooks/useApi';
import { sortOrdersById } from '@/components/orders/functions';
import CopyIcon from '@/components/icons/CopyIcon';
import OrderTabs from '@/components/orders/OrderTabs';
import { useAppContext } from '@/contexts/AppContext';
import useIsMobile from '@/hooks/useIsMobile';

const Orders = () => {
  const isMobile = useIsMobile();
  const { resetForms } = useAppContext();
  const { data, makeApiCall } = useApi();

  const getData = useCallback(async () => {
    await makeApiCall({
      url: '/orders/filteredList',
      method: 'GET',
    });
  }, [makeApiCall]);

  useEffect(() => {
    getData();
    resetForms();
  }, []);

  const sortedData = data ? [...data].sort(sortOrdersById) : [];

  const columns = isMobile ? mobileColumns : desktopColumns;

  return (
    <>
      <Header
        title="Orders"
        primaryAction={{ text: 'NEW ORDER', link: 'new' }}
      />
      <DataTable columns={columns} data={sortedData} />
    </>
  );
};

const CreateOrder = () => {
  const { duplicateForm, currentForm } = useAppContext();

  return (
    <>
      <Header
        title="New Order"
        previousRoute="/orders"
        primaryAction={{
          text: 'DUPLICATE',
          icon: <CopyIcon color="white" />,
          action: duplicateForm,
        }}
      />
      <OrderTabs />
      <OrderForm initialData={currentForm} viewMode="create" />
    </>
  );
};

const ViewOrder = () => {
  const navigate = useNavigate();
  const { duplicateExistingOrder } = useAppContext();
  const [searchParams] = useSearchParams();
  const _id = searchParams.get('id');
  const { data, makeApiCall } = useApi();

  const getDriver = async () => {
    await makeApiCall({
      url: '/orders/getOrder',
      method: 'POST',
      data: { _id },
    });
  };

  const handleDuplicateForm = () => {
    duplicateExistingOrder(data);
    navigate('/orders/new');
  };

  useEffect(() => {
    getDriver();
  }, []);

  return (
    <>
      <Header
        title="View Order"
        previousRoute="/orders"
        primaryAction={{
          text: 'DUPLICATE',
          icon: <CopyIcon color="white" />,
          action: handleDuplicateForm,
        }}
      />
      <OrderTabs />
      <OrderForm initialData={data} viewMode="view" />
    </>
  );
};

const EditOrder = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { duplicateExistingOrder } = useAppContext();
  const _id = searchParams.get('id');
  const { data, makeApiCall } = useApi();

  const getDriver = async () => {
    await makeApiCall({
      url: '/orders/getOrder',
      method: 'POST',
      data: { _id },
    });
  };

  const handleDuplicateForm = () => {
    duplicateExistingOrder(data);
    navigate('/orders/new');
  };

  useEffect(() => {
    getDriver();
  }, []);

  return (
    <>
      <Header
        title="Edit Order"
        previousRoute="/orders"
        primaryAction={{
          text: 'DUPLICATE',
          icon: <CopyIcon color="white" />,
          action: handleDuplicateForm,
        }}
      />
      <OrderTabs />
      <OrderForm initialData={data} viewMode="edit" />
    </>
  );
};

export { Orders, CreateOrder, ViewOrder, EditOrder };
