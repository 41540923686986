import React from 'react'

const Divider = () => {
  return (
    <div className='py-4'>
      <hr />
    </div>
  )
}

export default Divider;