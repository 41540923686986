// TableBody.jsx
import React from 'react';
import { TableBody as TableBodyPrimitive, TableCell, TableRow } from './table';
import { flexRender } from '@tanstack/react-table';

function TableBody({ rows, columns }) {
  return (
    <TableBodyPrimitive>
      {rows?.length ? (
        rows.map((row) => (
          <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
            {row.getVisibleCells().map((cell) => (
              <TableCell
                key={cell.id}
                className={cell.column.columnDef.meta?.className ?? ''}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </TableCell>
            ))}
          </TableRow>
        ))
      ) : (
        <TableRow>
          <TableCell colSpan={columns?.length} className="h-24 text-center">
            No results.
          </TableCell>
        </TableRow>
      )}
    </TableBodyPrimitive>
  );
}

export default TableBody;
