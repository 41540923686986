import { formatCurrency, parseCurrency } from '@/lib/utils';

const clearForm = ({ setValue, getValues }) => {
  const { accesorials } = getValues();
  setValue('total', formatCurrency(0));
  setValue('haulTotal', formatCurrency(0));
  setValue('truckTotal', formatCurrency(0));
  setValue('truckSubtotal', formatCurrency(0));
  const newAccesorials = accesorials.map((row) => ({
    ...row,
    amount: formatCurrency(0),
    newAccTotal: formatCurrency(0),
  }));
  setValue('accesorials', newAccesorials);
};

const calculateTotals = (form, totalKey) => {
  const { rate, quantity, total, accesorials } = form.getValues();

  // Check if rate and quantity are provided
  if (!rate || !quantity) {
    clearForm(form);
    return;
  }

  // Calculate total material cost based on the provided parameters
  const totalMaterial =
    totalKey === 'total'
      ? parseCurrency(rate) * parseFloat(quantity)
      : total
      ? parseCurrency(total)
      : 0;

  // Calculate accesorials total and update accesorials array
  const { accesorialsTotal, newAccesorials } = accesorials.reduce(
    (acc, row) => {
      const percentage = row.percentage && parseFloat(row.percentage);
      const amount = Number(
        percentage ? (totalMaterial * percentage) / 100 : 0
      );
      const newAccTotal = getNewAccTotal({ ...row, amount });

      acc.accesorialsTotal += amount;
      acc.newAccesorials.push({
        ...row,
        amount: formatCurrency(amount),
        newAccTotal: formatCurrency(newAccTotal),
      });

      return acc;
    },
    { accesorialsTotal: 0, newAccesorials: [] }
  );
  form.setValue('accesorials', newAccesorials);

  // Update form values based on the calculated total material
  if (totalKey === 'total') {
    form.setValue(totalKey, formatCurrency(totalMaterial));
  }

  // Calculate and update haul total in the form
  const haulTotal = totalMaterial + accesorialsTotal;
  form.setValue('haulTotal', formatCurrency(haulTotal));

  calculateTruckTotal(form);
};

function getNewAccTotal({ amount, truckPercentage, changeTruckPercentage }) {
  if (!changeTruckPercentage) {
    return 0;
  }

  if (amount && truckPercentage) {
    const parsedAmount = parseCurrency(amount);

    const percentage = parseFloat(truckPercentage);

    return (parsedAmount * percentage) / 100;
  }

  return parseCurrency(amount);
}

export const handleCustomAmount = (form) => {
  const { total, accesorials } = form.getValues();
  const totalMaterial = parseCurrency(total);

  // Use reduce to calculate the total accesorials amount
  let accesorialsTotal = 0;
  const newAccesorials = accesorials.map((row) => {
    const newAccTotal = row.amount
      ? parseCurrency(row.amount)
      : getNewAccTotal(row);
    accesorialsTotal += newAccTotal;

    const formattedNewAccTotal = row.truckPercentage
      ? formatCurrency(newAccTotal)
      : '';

    return { ...row, newAccTotal: formattedNewAccTotal };
  });
  form.setValue('accesorials', newAccesorials);

  // Calculate and update haul total in the form
  const haulTotal = totalMaterial + accesorialsTotal;

  form.setValue('haulTotal', formatCurrency(haulTotal));
  calculateTruckTotal(form);
};

export const calculateNewAccTotal = (form) => {
  const { accesorials } = form.getValues();

  const newAccesorials = accesorials.map((row) => {
    const newAccTotal = getNewAccTotal(row);

    const formattedNewAccTotal = row.truckPercentage
      ? formatCurrency(newAccTotal)
      : '';

    return { ...row, newAccTotal: formattedNewAccTotal };
  });
  form.setValue('accesorials', newAccesorials);
  calculateTruckTotal(form);
};

export const calculateTruckTotal = ({ getValues, setValue }) => {
  const { accesorials, driverOrCarrierPercentage, haulTotal } = getValues();

  if (!driverOrCarrierPercentage || !haulTotal) {
    return;
  }

  const changeTruckPercentage = accesorials.some(
    (row) => row?.changeTruckPercentage
  );

  let truckSubtotal =
    (parseCurrency(haulTotal) * parseFloat(driverOrCarrierPercentage)) / 100;

  accesorials.forEach((row) => {
    if (row?.changeTruckPercentage) {
      const amount = parseCurrency(row.amount) ?? 0;
      truckSubtotal -= (amount * parseFloat(driverOrCarrierPercentage)) / 100;
    }
  });

  let truckTotal = accesorials.reduce((acc, row) => {
    const newAccTotal = parseCurrency(row.newAccTotal) ?? 0;
    return acc + newAccTotal;
  }, truckSubtotal);

  setValue(
    'truckTotal',
    formatCurrency(changeTruckPercentage ? truckTotal : truckSubtotal)
  );
  setValue('truckSubtotal', formatCurrency(truckSubtotal));
};

export const calculateOrderTotal = (form) => {
  calculateTotals(form, 'total');
};

export const calculateHaulTotal = (form) => {
  calculateTotals(form, 'haulTotal');
};

export function sortOrdersById(a, b) {
  const getIdNumber = (element) => {
    const num = parseInt(element?.id?.split('-')[1]) ?? 0;
    return isNaN(num) ? 0 : num;
  };

  const numA = getIdNumber(a);
  const numB = getIdNumber(b);

  return numB - numA;
}
export function groupDataByType(data, pushNameOnly = false) {
  const result = {};

  data.forEach((item) => {
    const { type, name } = item;

    if (!result[type]) {
      result[type] = [];
    }

    result[type].push(pushNameOnly ? name : item);
  });

  return result;
}
