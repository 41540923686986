const { z } = require('zod');

const orderSchema = z.object({
  proNumber: z.string().min(1),
  shipper: z.string().min(1),
  shipperNumber: z.string().min(1),
  consignee: z.string().min(1),
  consigneeNumber: z.string().min(1),
  billTo: z.string().min(1),
  billToNumber: z.string().min(1),
  rate: z.string().min(1),
  quantity: z.string().min(1),
  material: z.string().min(1),
  total: z.string(),
  haulTotal: z.string(),
  truckTotal: z.string(),
  truckSubtotal: z.string(),
  driverOrCarrierPercentage: z.string(),
  division: z.string(),
  jobName: z.string(),
  pickupDate: z.coerce.date(),
  dropoffDate: z.coerce.date(),
  notes: z.string(),
  pickupTime: z.string(),
  ticketNumber: z.string().min(1),
  pickupLocation: z.string().min(1),
  dropoffLocation: z.string().min(1),
  commissionAgent1: z.string(),
  agentPercentage1: z.string(),
  commissionAgent2: z.string(),
  agentPercentage2: z.string(),
});

const driverSchema = z
  .object({
    dbDriverId: z.string(),
    mainTruckNumber: z.string(),
    driverId: z.string(),
    driverName: z.string(),
    carrierCompanyName: z.string(),
  })
  .superRefine((data, ctx) => {
    if (data.carrierCompanyName) {
      return true;
    }
    if (!data.driverId) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Driver's ID is required.",
        path: ['driverId'],
      });
    }
    if (!data.driverName) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: "Driver's Name is required.",
        path: ['driverName'],
      });
    }
    if (!data.mainTruckNumber) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Truck number is required.',
        path: ['mainTruckNumber'],
      });
    }
  });

const formSchema = z.intersection(orderSchema, driverSchema);

const initialOrderState = {
  rate: '',
  carrierCompanyName: '',
  dbDriverId: '',
  driverId: '',
  driverName: '',
  mainTruckNumber: '',
  quantity: '',
  billTo: '',
  billToNumber: '',
  notes: '',
  division: '',
  jobName: '',
  material: '',
  total: '',
  haulTotal: '',
  truckTotal: '',
  truckSubtotal: '',
  driverOrCarrierPercentage: '',
  pickupDate: '',
  pickupTime: '',
  dropffDate: '',
  dropffTime: '',
  proNumber: '',
  ticketNumber: '',
  shipper: '',
  shipperNumber: '',
  consignee: '',
  consigneeNumber: '',
  rateCurrency: 'USD',
  quantityUnit: 'Ton',
  commissionAgent1: '',
  agentPercentage1: '',
  commissionAgent2: '',
  agentPercentage2: '',
  accesorials: [
    {
      id: '',
      option: '',
      percentage: '',
      amount: '',
      newAccTotal: '',
      truckPercentage: '',
      changeTruckPercentage: false,
    },
  ],
};

export { formSchema, initialOrderState };
