import React, { useMemo } from 'react';
import { Combobox } from '../ui/combobox';
import DatePicker from '../ui/DatePickerWithRange';
import {
  DEFAULT_ORDER_STATUS,
  MATERIAL_OPTIONS,
  STATUS_OPTIONS,
} from '@/lib/constants';
import { removeDuplicatesByProperty } from '@/lib/utils';

const OrderFilters = ({ data, table }) => {
  const setFilterValue = (propertyName, newValues) => {
    table?.getColumn(propertyName)?.setFilterValue(newValues);
  };

  // Memoized options for each property
  const memoizedOptions = useMemo(() => {
    return {
      id: removeDuplicatesByProperty(data, 'id'),
      proNumber: removeDuplicatesByProperty(data, 'proNumber'),
      mainTruckNumber: removeDuplicatesByProperty(data, 'mainTruckNumber'),
      carrierCompanyName: removeDuplicatesByProperty(
        data,
        'carrierCompanyName'
      ),
      ticketNumber: removeDuplicatesByProperty(data, 'ticketNumber'),
    };
  }, [data]);

  return (
    <div className="mb-6">
      <div
        // onClick={toggleFilters}
        className="flex text-sm text-[#A8A7A7]"
      >
        Filters
      </div>
      {
        <div className="flex gap-7 mt-6">
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.id}
            label="ID"
            propertyName="id"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.proNumber}
            label="ProNumber"
            propertyName="proNumber"
          />
          <DatePicker column={table.getColumn('pickupDate')} allowRange />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.mainTruckNumber}
            label="Truck#"
            propertyName="mainTruckNumber"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.carrierCompanyName}
            label="Carrier"
            propertyName="carrierCompanyName"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={MATERIAL_OPTIONS.sort()}
            label="Material"
            propertyName="material"
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={STATUS_OPTIONS.sort()}
            label="Status"
            propertyName="status"
            defaultOptions={DEFAULT_ORDER_STATUS}
          />
          <Combobox
            setFilterValue={setFilterValue}
            options={memoizedOptions.ticketNumber}
            label="Ticket #"
            propertyName="ticketNumber"
          />
        </div>
      }
    </div>
  );
};

export default OrderFilters;
