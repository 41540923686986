import React from 'react';

const DispatchIcon = ({ color, ...props }) => {
  return (
    <svg
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.333313 11.3333V0.666668L13 6L0.333313 11.3333ZM1.44441 9.63335L10.1222 6L1.44441 2.33332V4.97778L5.46665 6L1.44441 7V9.63335Z"
        fill={color}
      />
    </svg>
  );
};

export default DispatchIcon;
