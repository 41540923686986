import { useCallback, useEffect, useMemo, useState } from 'react';
import Cookies from 'js-cookie';
import useApi from './useApi';
import { isArray } from '@/lib/utils';
import { calculateTruckTotal } from '@/components/orders/functions';

const useDriverSelection = (form) => {
  const { data, makeApiCall } = useApi();

  const [driverIdOptions, setDriverIdOptions] = useState([]);
  const [driverNameOptions, setDriverNameOptions] = useState([]);
  const [truckNumberOptions, setTruckNumberOptions] = useState([]);

  const getDrivers = async () => {
    await makeApiCall({
      url: '/drivers/filteredList',
      method: 'POST',
      data: {
        agentId: Cookies.get('agentId'),
      },
    });
  };

  useEffect(() => {
    getDrivers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const drivers = useMemo(
    () => (isArray(data?.drivers) ? data.drivers : []),
    [data]
  );

  const filterDriversWithEmptyCarrier = useCallback((drivers) => {
    return drivers.filter(
      (driver) =>
        !driver.carrierCompanyName || driver.carrierCompanyName.trim() === ''
    );
  }, []);

  const handleCarrierCompanyName = () => {
    const { carrierCompanyName } = form.getValues();

    const filteredDrivers = carrierCompanyName
      ? drivers.filter(
          (driver) => driver.carrierCompanyName === carrierCompanyName
        )
      : filterDriversWithEmptyCarrier(drivers);

    const optionsToSet = {
      driverIds: filteredDrivers.map((driver) => driver.driverId),
      driverNames: filteredDrivers.map((driver) => driver.driverName),
      truckNumbers: filteredDrivers.map((driver) => driver.mainTruckNumber),
    };

    setDriverIdOptions(optionsToSet.driverIds);
    setDriverNameOptions(optionsToSet.driverNames);
    setTruckNumberOptions(optionsToSet.truckNumbers);
  };

  const handleSelectDriver = ({ name, value }) => {
    const driver = drivers.find((driver) => driver[name] === value);
    const clearValues = () => {
      form.setValue('dbDriverId', '');
      form.setValue('driverId', '');
      form.setValue('driverName', '');
      form.setValue('mainTruckNumber', '');
      form.setValue('driverOrCarrierPercentage', '');
    };

    if (driver) {
      form.setValue('dbDriverId', driver._id);
      form.setValue('driverId', driver.driverId);
      form.setValue('driverName', driver.driverName);
      form.setValue('mainTruckNumber', driver.mainTruckNumber);
      form.setValue(
        'driverOrCarrierPercentage',
        driver.driverOrCarrierPercentage
      );

      /* calculate truck total */
      driver.driverOrCarrierPercentage && calculateTruckTotal(form);
    } else {
      clearValues();
    }
  };

  useEffect(() => {
    if (drivers.length > 0) {
      // Filter out drivers without carrierCompanyName or empty carrierCompanyName
      const filteredDrivers = filterDriversWithEmptyCarrier(drivers);

      // Set options based on filtered drivers
      setDriverIdOptions(filteredDrivers.map((driver) => driver.driverId));
      setDriverNameOptions(filteredDrivers.map((driver) => driver.driverName));
      setTruckNumberOptions(
        filteredDrivers.map((driver) => driver.mainTruckNumber)
      );
    }
  }, [drivers, filterDriversWithEmptyCarrier]);

  return {
    driverIdOptions: driverIdOptions.sort(),
    driverNameOptions: driverNameOptions.sort(),
    truckNumberOptions: truckNumberOptions.sort(),
    handleCarrierCompanyName,
    handleSelectDriver,
  };
};

export default useDriverSelection;
