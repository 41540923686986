import React from 'react';
import { Image } from 'lucide-react';
import { Label } from './label';
import { FormFieldInput } from './form';
import UploadedFiles from './UploadedFiles';
import useFileUploadLogic from '@/hooks/useFileUploadLogic';

const FileUpload = ({ control, isViewMode }) => {
  const {
    fileInputRef,
    files,
    remove,
    uploadMultipleFiles,
    // Add other functions as needed
  } = useFileUploadLogic(control);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    uploadMultipleFiles(newFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.dataTransfer.files);
    uploadMultipleFiles(newFiles);
  };

  return (
    <div>
      {!isViewMode && (
        <>
          <Label>Files*</Label>
          <div
            className="flex items-center justify-center bg-white border-dashed border-2 border-primary p-4 rounded-lg mb-4"
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <div
              className="flex items-center flex-col text-gray-500 cursor-pointer"
              onClick={handleButtonClick}
            >
              <Image className="text-4xl" color="#FFC222" />
              <p className="mt-2">
                Drag & drop your files or{' '}
                <span className="text-primary">Browse Files here</span>
              </p>
            </div>
            <input
              type="file"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
              multiple
            />
          </div>
        </>
      )}

      <div className="md:grid grid-cols-2 gap-5">
        <div>
          <UploadedFiles
            files={files}
            remove={remove}
            isViewMode={isViewMode}
          />
        </div>
        <FormFieldInput
          disabled={isViewMode}
          label="Ticket Number"
          name="ticketNumber"
          placeholder="000000"
          control={control}
        />
      </div>
    </div>
  );
};

export default FileUpload;
