import { useState } from 'react';
import { Button } from '../ui/Button';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
} from '../ui/alert-dialog';
import useApi from '@/hooks/useApi';
import { OrderDispatcherTable } from './OrderDispatcherTable';
import { useNavigate } from 'react-router-dom';
import { Check, X } from 'lucide-react';
import DispatchIcon from '../icons/DispatchIcon';
import OrderIcon from '../icons/OrderIcon';

const OrderDispatcher = ({ selectedRows }) => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const data = selectedRows.map((row) => row.original);

  const handleDispatchClick = () => {
    // Display the confirmation modal
    setIsModalVisible(true);
  };

  const handleConfirmDispatch = async () => {
    // Dispatch orders and close the confirmation modal
    const ids = selectedRows.map((row) => row.original._id);
    const { data } = await makeApiCall({
      url: '/orders/dispatch',
      method: 'POST',
      data: JSON.stringify(ids),
    });
    if (data?.success) {
      navigate(0); // reload page
    }
    setIsModalVisible(false);
  };

  const handleCancelDispatch = () => {
    // Close the confirmation modal without dispatching orders
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        onClick={handleDispatchClick}
        disabled={selectedRows.length === 0}
      >
        DISPATCH ORDERS <DispatchIcon className="ml-2" color={'white'} />
      </Button>

      {/* Confirmation Modal */}
      <AlertDialog open={isModalVisible}>
        <AlertDialogContent className="max-w-5xl">
          <AlertDialogHeader>
            <div className="flex gap-2 items-center relative mb-2">
              <OrderIcon color="#FFC222" /> READY FOR DISPATCH
              <X
                color="#015CAA"
                onClick={handleCancelDispatch}
                className="absolute right-0 cursor-pointer"
              />
            </div>
          </AlertDialogHeader>
          <OrderDispatcherTable data={data} />
          <AlertDialogFooter>
            <div className="flex justify-center w-full gap-5 mt-5 items-center">
              <AlertDialogCancel
                onClick={handleCancelDispatch}
                className="mt-0"
              >
                CANCEL
              </AlertDialogCancel>
              <AlertDialogAction onClick={handleConfirmDispatch}>
                CONFIRM <Check className="ml-1" size={18} />
              </AlertDialogAction>
            </div>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default OrderDispatcher;
