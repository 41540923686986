import React from 'react';
import Logo from './Logo';
import { LogOut, Settings, Truck } from 'lucide-react';
import OrderIcon from './icons/OrderIcon';
import { MenuItem } from './ui/MenuItem';
import { useAuth } from './auth/AuthProvider';
import { Link } from 'react-router-dom';
import { useAppContext } from '@/contexts/AppContext';
import { cn } from '@/lib/utils';
import SubMenuNavItem from './ui/SubMenuItem';

const Sidebar = () => {
  const { logout } = useAuth();
  const { isMenuOpen } = useAppContext();

  return (
    <div
      className={cn(
        isMenuOpen ? 'translate-x-0' : '-translate-x-full',
        'flex absolute w-42 bg-[#F4F4F4] px-4 py-8 flex-col justify-between rounded-xl z-50 h-full transition-transform duration-300 transform',
        'md:translate-x-0 md:relative md:px-0'
      )}
    >
      <div className="flex flex-col items-center">
        <Logo color="#3B3939" width="120" className="mb-6 mx-6" />
        {/* Navigation List */}
        <ul className="mt-4 w-full px-3">
          <MenuItem text="Orders" link="/orders" icon={OrderIcon} />
          <MenuItem text="Drivers" link="/drivers" icon={Truck} />
          <SubMenuNavItem
            text="Settings"
            icon={Settings}
            subMenuItems={[
              { text: 'Orders', link: '/settings/orders' },
              { text: 'Users', link: '/settings/users' },
              // Add more sub-menu items as needed
            ]}
          />
        </ul>
      </div>

      {/* Sidebar Footer */}
      <div className="flex flex-col items-center">
        <hr className="mb-10 border-gray w-full" />
        <Link onClick={logout} to="/">
          <div className={'flex items-center mb-1 py-2 px-5 rounded-lg'}>
            <LogOut width="18" color={'#545454'} />
            <p className={'text-sm ml-2.5'}>Logout</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
