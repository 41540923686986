import { formatCurrency, parseCurrency, parseStringToDate } from '../utils';

// Function to count occurrences of each proNumber
const countOccurrences = (data) => {
  return data.reduce((acc, { original }) => {
    const proNumber = original.proNumber;
    acc[proNumber] = (acc[proNumber] || 0) + 1;
    return acc;
  }, {});
};

function sortDataByProNumber(data) {
  return data.slice().sort((a, b) => {
    // Compare proNumber values for sorting
    if (a.original.proNumber < b.original.proNumber) {
      return -1;
    } else if (a.original.proNumber > b.original.proNumber) {
      return 1;
    } else {
      return 0;
    }
  });
}

export const formatDataForExport = (data) => {
  // Create an object to store the count of repetitions for each proNumber
  const proNumberItemCounts = countOccurrences(data);

  let sequence = 0;
  let currentProNumber = null;

  const sortedData = sortDataByProNumber(data);

  const result = sortedData.map(({ original }) => {
    // Update or initialize the count for the current proNumber
    const {
      total,
      proNumber,
      accesorials,
      driverOrCarrierPercentage,
      pickupDate,
    } = original;

    const parsedPercentage = parseFloat(driverOrCarrierPercentage);

    const { fuelSurcharge, waitTime, otherAcc } = extractOptions(
      accesorials,
      parsedPercentage
    );

    const truckTotalNoAcc =
      total && driverOrCarrierPercentage
        ? formatCurrency((parseCurrency(total) * parsedPercentage) / 100)
        : '';

    // Reset sequence counter if proNumber changes
    if (proNumber !== currentProNumber) {
      currentProNumber = proNumber;
      sequence = 0;
    }

    sequence++;

    return {
      ...original,
      sequence,
      truckTotalNoAcc,
      pickupDate: parseStringToDate(pickupDate),
      fscPercentage: fuelSurcharge?.percentage,
      fscTotal: fuelSurcharge?.amount,
      waitTimeTotal: waitTime?.amount,
      otherAccTotal: otherAcc?.total,
      fscTruckTotal: fuelSurcharge?.truckTotal,
      waitTimeTruckTotal: waitTime?.truckTotal,
      otherAccTruckTotal: otherAcc?.truckTotal,
      proNumberRepetitions: proNumberItemCounts[proNumber],
    };
  });

  return result;
};

export function extractOptions(accesorials, driverOrCarrierPercentage) {
  let otherAcc = {};
  let waitTime = {};
  let fuelSurcharge = {};

  if (accesorials === undefined) {
    return {
      fuelSurcharge,
      waitTime,
      otherAcc,
    };
  }

  const { total, truckTotal } = accesorials.reduce(
    (accumulator, item) => {
      // Calculate truckTotal based on conditions
      item.truckTotal = item.changeTruckPercentage
        ? parseCurrency(item.newAccTotal)
        : (parseCurrency(item.amount) * driverOrCarrierPercentage) / 100;

      // Categorize accesorials based on option type
      if (item.option === 'FUEL SURCHARGE') {
        fuelSurcharge = item;
      } else if (item.option === 'WAIT TIME') {
        waitTime = item;
      } else {
        // Accumulate totals for other options
        accumulator.total += parseCurrency(item.amount);
        accumulator.truckTotal += item.truckTotal;
      }

      return accumulator;
    },
    { total: 0, truckTotal: 0 }
  );

  // Format truck totals using a function
  const formatTruckTotal = (obj) =>
    obj?.truckTotal ? formatCurrency(obj.truckTotal) : '';

  waitTime.truckTotal = formatTruckTotal(waitTime);
  fuelSurcharge.truckTotal = formatTruckTotal(fuelSurcharge);

  return {
    waitTime,
    fuelSurcharge,
    otherAcc: {
      total: total ? formatCurrency(total) : '',
      truckTotal: truckTotal ? formatCurrency(truckTotal) : '',
    },
  };
}
