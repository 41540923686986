import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import usernameIcon from '../../../assets/username.svg';
import passwordIcon from '../../../assets/password.svg';
import ErrorMessage from '../../ui/ErrorMessage';
import { Label } from '@/components/ui/label';
import { useAuth } from '../AuthProvider';

const LoginForm = () => {
  const navigate = useNavigate();
  const { isAuthenticated, login, message } = useAuth();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    login(username, password);
  };

  // Effect to handle successful API response
  useEffect(() => {
    if (isAuthenticated) {
      navigate('/orders');
    }
  }, [isAuthenticated, navigate]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-5">
        <Label htmlFor="username">Username</Label>
        <Input
          type="text"
          value={username}
          required
          icon={<img src={usernameIcon} alt="Username" />}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div className="mb-5">
        <Label htmlFor="password">Password</Label>
        <Input
          type="password"
          value={password}
          required
          icon={<img src={passwordIcon} alt="Password" />}
          onChange={(e) => setPassword(e.target.value)}
        />
        <div className="mt-2 mb-8 text-sm text-right font-semibold">
          <a href="/password-recory" className="text-primary">
            FORGOT YOUR PASSWORD?
          </a>
        </div>
      </div>
      <Button className="w-full">LOGIN</Button>
      <div className="mt-5">
        {message.type === 'error' && <ErrorMessage message={message.text} />}
        {message.type === 'info' && <p>{message.text}</p>}
      </div>
    </form>
  );
};

export default LoginForm;
