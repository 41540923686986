import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input } from '../ui/Input';
import { Button } from '../ui/Button';
import LoginLayout from './Login/LoginLayout';
import ErrorMessage from '../ui/ErrorMessage';
import usernameIcon from '../../assets/username.svg';
import useApi from '@/hooks/useApi';
import useForm from '@/hooks/useForm';
import { useAuth } from './AuthProvider';

const RecoverPassword = () => {
  const navigate = useNavigate();

  const { message, setMessage } = useAuth();

  const [step, setStep] = useState(1); // 1 for email submission, 2 for verification code and new password

  const { makeApiCall, error } = useApi(); // Destructure data and error from the useApi hook

  const { formData, handleChange, resetForm } = useForm({
    // Initial form state
    username: '',
    password: '',
    confirmPassword: '',
    confirmationCode: '',
  });

  const { username, password, confirmPassword, confirmationCode } = formData;

  const handleSubmit = async (e) => {
    e.preventDefault();

    setMessage({ text: '', type: null });

    if (step === 1) {
      // Step 1: Submit email for verification code
      const { data } = await makeApiCall({
        url: '/auth/password-recovery',
        method: 'POST',
        data: { username },
      });
      handleStep1(data);
    } else if (step === 2) {
      // Step 2: Submit verification code for new password
      const { data } = await makeApiCall({
        url: '/auth/confirm-forgot-password',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(formData),
      });
      handleStep2(data);
    }
  };

  // Helper function for step 2
  const handleStep2 = useCallback(
    (data) => {
      if (data?.success) {
        setMessage({ text: data.message, type: 'info' });
        setStep(1);
        resetForm();
        navigate('/');
      } else {
        if (data?.message) {
          setMessage({ text: data.message, type: 'error' });
        }
      }
    },
    [setMessage, resetForm, navigate]
  );

  // Helper function for step 1
  const handleStep1 = useCallback(
    (data) => {
      if (data?.error) {
        setMessage({ text: data.error.message, type: 'error' });
      } else {
        setStep(2);
        if (data?.message) {
          setMessage({ text: data.message, type: 'info' });
        }
      }
    },
    [setMessage]
  );

  useEffect(() => {
    if (error) {
      setMessage({ text: error.message, type: 'error' });
    }
  }, [error, setMessage]);

  return (
    <LoginLayout
      title={<h1 className="text-4xl font-bold mb-4">Password recovery</h1>}
      description={
        step === 1
          ? 'Please enter your email or username to search for your account.'
          : 'Please enter the verification code and the new password'
      }
    >
      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div>
            <Input
              type="text"
              value={username}
              required
              placeholder="Username"
              className="mb-8"
              icon={<img src={usernameIcon} alt="Username" />}
              onChange={(e) => handleChange('username', e.target.value)}
            />
            <Button className="w-full">NEXT</Button>
          </div>
        )}
        {step === 2 && (
          <>
            <div className="space-y-5">
              <Input
                type="text"
                value={confirmationCode}
                required
                placeholder="Verification code"
                onChange={(e) =>
                  handleChange('confirmationCode', e.target.value)
                }
              />
              <Input
                value={password}
                type="password"
                required
                placeholder="New password"
                onChange={(e) => handleChange('password', e.target.value)}
              />
              <Input
                type="password"
                value={confirmPassword}
                required
                placeholder="Confirm password"
                onChange={(e) =>
                  handleChange('confirmPassword', e.target.value)
                }
              />
            </div>
            <Button className="w-full uppercase mt-8">Reset Password</Button>
          </>
        )}
      </form>
      <div className="mt-5">
        {message.type === 'error' && <ErrorMessage message={message.text} />}
        {message.type === 'info' && <p>{message.text}</p>}
      </div>
    </LoginLayout>
  );
};

export default RecoverPassword;
