import * as z from 'zod';

export const shipperConfig = {
  schema: {
    name: z.string().min(1),
    number: z.string().min(1),
    address: z.string().min(1),
  },
  defaultValues: {
    name: '',
    number: '',
    address: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Full Name',
      placeholder: 'Type name',
      className: 'col-span-2',
    },
    { name: 'number', label: 'Number', placeholder: 'Type number' },
    {
      name: 'address',
      label: 'Address',
      placeholder: 'Type address',
    },
  ],
};

export const accesorialConfig = {
  schema: {
    name: z.string().min(1),
    percentage: z.string().min(1),
  },
  defaultValues: {
    name: '',
    percentage: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Type name',
    },
    { name: 'percentage', label: 'Percentage', placeholder: '0.00' },
  ],
};

export const unitsConfig = {
  schema: {
    name: z.string().min(1),
  },
  defaultValues: {
    name: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Unit',
      placeholder: 'Type Unit',
      className: 'col-span-2',
    },
  ],
};

export const trailerTypeConfig = {
  schema: {
    name: z.string().min(1),
  },
  defaultValues: {
    name: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Trailer Type',
      placeholder: 'Type Trailer Type',
      className: 'col-span-2',
    },
  ],
};

export const rateConfig = {
  schema: {
    shipper: z.string(),
    consignee: z.string(),
    billTo: z.string(),
    rate: z.string(),
    unit: z.string(),
  },
  defaultValues: {
    shipper: '',
    consignee: '',
    billTo: '',
    rate: '',
    unit: '',
  },
  fields: [
    {
      name: 'shipper',
      label: 'Shipper',
      type: 'select',
      placeholder: 'Select Shipper',
    },
    {
      name: 'consignee',
      label: 'Consignee',
      type: 'select',
      placeholder: 'Select Consignee',
    },
    {
      name: 'billTo',
      label: 'Bill To',
      placeholder: 'Select Bill To',
      type: 'select',
      className: 'col-span-2',
    },
    { name: 'rate', label: 'Rate', placeholder: '0.00', type: 'number' },
    {
      name: 'unit',
      label: 'Unit',
      type: 'select',
      placeholder: 'Select Unit',
    },
  ],
};

export const agentConfig = {
  schema: {
    agentId: z.string().min(1),
    companyName: z.string().min(1),
    status: z.string().min(1),
    name: z.string().min(1),
    email: z.string().min(1),
    phoneNumber: z.string().min(1),
    businessAddress: z.string().min(1),
    password: z.string().min(1),
  },
  defaultValues: {
    agentId: '',
    companyName: '',
    status: '',
    name: '',
    email: '',
    phoneNumber: '',
    businessAddress: '',
    password: '',
  },
  fields: [
    {
      name: 'agentId',
      label: 'Agent ID',
      placeholder: 'Type Agent ID',
      className: 'col-span-2',
    },
    {
      name: 'companyName',
      label: 'Company Name',
      placeholder: 'Type Company Name',
    },
    {
      name: 'status',
      label: 'Status',
      placeholder: 'Type Status',
    },
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Type Name',
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Type Email',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      placeholder: 'Type Phone Number',
    },
    {
      name: 'businessAddress',
      label: 'Business Address',
      placeholder: 'Type Business Address',
    },
    {
      name: 'password',
      label: 'Password',
      placeholder: 'Type Password',
    },
  ],
};

export const userConfig = {
  schema: {
    name: z.string().min(1),
    email: z.string().min(1),
    phoneNumber: z.string().min(1),
    password: z.string().min(1),
    active: z.string(),
  },
  defaultValues: {
    name: '',
    email: '',
    phoneNumber: '',
    password: '',
    active: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Name',
      placeholder: 'Type Name',
    },
    {
      name: 'email',
      label: 'Email',
      placeholder: 'Type Email',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      placeholder: 'Type Phone Number',
    },
    {
      name: 'password',
      label: 'Password',
      placeholder: 'Type Password',
    },
    {
      name: 'active',
      label: 'Active',
      type: 'check',
    },
  ],
};

export const rolesConfig = {
  schema: {
    name: z.string().min(1),
  },
  defaultValues: {
    name: '',
  },
  fields: [
    {
      name: 'name',
      label: 'Trailer Type',
      placeholder: 'Type Trailer Type',
      className: 'col-span-2',
    },
  ],
};
