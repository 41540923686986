import React, { useState } from 'react';
import Logo from '../Logo';
import tireVector from '../../assets/tire.svg';
import { Button } from '../ui/Button';
import axios from 'axios';
import Cookies from 'js-cookie';
import { AlertDialogWrapper2 } from '../ui/alert-dialog';

const TermsAndConditions = ({setTermsAgreed, driver}) => {

    const baseURL = process.env.REACT_APP_ROOT_API;
    const [terms, setTerms] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleClick = (e) => {
        e.preventDefault()
        setTermsAgreed(true);
        axios.post(`${baseURL}/drivers/setDriver`, {
            ...driver,
            termsAgreed: true,
            termsAgreedDateTime: new Date().toISOString(),
        }, {
            headers: {
                Authorization: `Bearer ${Cookies.get('accessToken')}`
            }
        })
    }

    const handleDontAccept = () => {
      setIsModalOpen(true);
    }

    const handleReject = () => {
      setTermsAgreed(false);
      axios.post(`${baseURL}/drivers/setDriver`, {
          ...driver,
          termsAgreed: false,
          termsAgreedDateTime: new Date().toISOString(),
      }, {
          headers: {
              Authorization: `Bearer ${Cookies.get('accessToken')}`
          }
      })
    }

    const handleClickDisabled = () => {
      (!terms || !privacy) && alert("You have to accept the Terms and Conditions and the Privacy Policy");
    }

    return (
      <main>
        <AlertDialogWrapper2
          open={isModalOpen}
          setOpen={setIsModalOpen}
          onConfirm={handleReject}
          title={`Don\'t receive notifications`}
          description={`Are you sure you don\'t want to receive sms and email notifications? If you don\'t receive notifications, 
            you will have to login and manually check if you have new orders or if the orders that are assigned to you have been edited.`}
        />
        <div className="grid grid-cols-12 gap-2 min-h-screen overflow-x-hidden">
          <div className="col-span-12 md:col-span-5 relative py-12">
            <div className="flex flex-col items-center px-8">
              <Logo color="#013D71" className="z-10 mb-10 w-full max-w-[375px]"/>
              <img src={tireVector} alt="Tire Vector" className='absolute top-0 right-[-25%] z-[-1]' width={370}/>
              <div className="w-full max-w-[362px]">
                <div className="text-center">
                  <h1 style={{marginBottom: '10px', fontSize: '20px', fontWeight: 'bold'}}>SMS and Email Notifications</h1>
                  <p className='text-sm text-justify'>
                    At Connect A Load LLC, we respect your privacy and believe in transparency so that people and organizations can
                    control their data and make meaningful choices in how it is used.
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px'}}>
                    This Notice details how we handle personal information as well as how you can make choices related to your personal information.
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px'}}>
                    Scope of Global Privacy Notice
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px'}}>
                    Connect A Load LLC, and its affiliates and subsidiaries (collectively, “Connect A Load Operations LLC”) respect your privacy. 
                    This Global Privacy Notice (this, “Notice”) is global in nature and explains how Connect A Load LLC collects and processes personal 
                    information obtained via its websites, application software, and mobile apps.
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px'}}>
                    References to “mobile apps” include Connectar Advantage LLC® Mobile Application, TicketVP Web Application and any other mobile application 
                    produced, managed, and maintained by Connect A Load LLC.
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px'}}>
                    {`This Notice describes:

                    Updates to our Global Privacy Notice
                    Legal Basis for Collecting and Processing Personal Information;
                    Categories of Personal Information We Collect;
                    Sources from Which We May Collect Personal Information;
                    Purposes and Methods for Processing the Information We Collect;
                    Information We May Share;
                    Your Rights and Choices;
                    Data Retention;
                    Data Transfers;
                    Data Protection;
                    Cookie Statement;
                    Information of Children Under the Age of 16;
                    How You Can Contact Us.`}
                  </p>
                  <p className='text-sm text-justify' style={{marginTop: '10px', marginBottom: '10px'}}>
                    If you wish to stop receiving these messages, please contact xxxxxx@connectaload.com
                  </p>
                  <div style={{display: 'flex', gap:'10px'}}>
                    <input type='checkbox' style={{height: '42px', minWidth: '15px'}} checked={privacy} onChange={() => setPrivacy(!privacy)}/>
                    <span className='text-sm text-left' style={{marginTop: '10px'}}>
                      By checking this box, you agree to our Privacy Policy, which you can read here: <a href='https://www.connectaload.com/privacy-policy/' target='_blank' style={{color: 'blue'}}>Privacy Policy</a>
                    </span>
                  </div>
                  <div style={{display: 'flex', gap:'10px'}}>
                    <input type='checkbox' style={{height: '42px', minWidth: '15px'}} checked={terms} onChange={() => setTerms(!terms)}/>
                    <span className='text-sm text-left' style={{marginTop: '10px'}}>
                      By checking this box, you agree to our Terms and Conditions, which you can read here: <a href='https://www.connectaload.com/terms-and-condition/' target='_blank' style={{color: 'blue'}}>Terms And Conditions</a>
                    </span>
                  </div>
                  <div style={{display: 'flex', flexDirection: 'column', marginTop: '20px'}} onClick={handleClickDisabled}>
                    <Button disabled={!terms || !privacy} style={{marginTop: '10px'}} onClick={e => handleClick(e)}>I agree to receive sms and emails</Button>
                    <Button disabled={!terms || !privacy} style={{marginTop: '10px', backgroundColor: (terms && privacy) ? 'gray' : 'rgb(190 189 189)'}} onClick={e => handleDontAccept(e)}>I don't agree to receive sms and emails</Button>
                  </div>
                </div>
              </div>
            </div>
            <div className='bg-gradient-to-b from-transparent to-[#CCDEEE] absolute top-0 w-full h-full z-[-1] block md:hidden'></div>
          </div>
        </div>
      </main>
    );
  };
  
  export default TermsAndConditions;