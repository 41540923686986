'use client';

import * as React from 'react';
import { Check, ChevronDown, Clock } from 'lucide-react';

import Row from './Row';
import { cn } from '@/lib/utils';
import { ScrollArea } from './scroll-area';
import { Button } from '@/components/ui/Button';
import { Command, CommandGroup, CommandItem } from '@/components/ui/command';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '@/components/ui/popover';
import { HOUR_OPTIONS, MINUTE_OPTIONS } from '@/lib/constants';

const TimePicker = React.forwardRef(({ disabled, value, onChange }, ref) => {
  const [open, setOpen] = React.useState(false);
  const [hour, setHour] = React.useState('');
  const [minutes, setMinutes] = React.useState('');

  React.useEffect(() => {
    onChange(`${hour}:${minutes}`);
  }, [hour, minutes]);

  const handleReset = () => {
    setHour('');
    setMinutes('');
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={disabled}>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn(
            'w-full justify-between',
            disabled && 'text-muted-foreground'
          )}
        >
          <div className="flex gap-3 items-center">
            <Clock className="w-4" color="#514F4F" />
            {hour ? (
              minutes ? (
                `${hour}:${minutes}`
              ) : (
                hour
              )
            ) : value ? (
              value
            ) : (
              <span className="text-muted-foreground">Time</span>
            )}
          </div>
          <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto px-0.5 py-0 overflow-hidden">
        <Row gap={0}>
          <ScrollArea className="h-40">
            <Command>
              <CommandGroup>
                {HOUR_OPTIONS.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    onSelect={(currentValue) => {
                      setHour(currentValue === hour ? '' : currentValue);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        hour === item.value ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {item.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </ScrollArea>
          <ScrollArea className="h-40">
            <Command>
              <CommandGroup>
                {MINUTE_OPTIONS.map((item) => (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    onSelect={(currentValue) => {
                      setMinutes(currentValue === minutes ? '' : currentValue);
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        'mr-2 h-4 w-4',
                        minutes === item.value ? 'opacity-100' : 'opacity-0'
                      )}
                    />
                    {item.label}
                  </CommandItem>
                ))}
              </CommandGroup>
            </Command>
          </ScrollArea>
        </Row>
        <div className="flex justify-end">
          <Button variant="link" onClick={handleReset}>
            CLEAR
          </Button>
        </div>
      </PopoverContent>
    </Popover>
  );
});

export default TimePicker;
