import React from 'react';
import DashboardDriversLayout from '@/components/drivers/DashboardDriversLayout';

const DashboardDrivers = () => {
  return (
    <>
      <DashboardDriversLayout />
    </>
  );
};

export { DashboardDrivers };
