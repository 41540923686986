import React from 'react';
import {
  TableRow,
  TableHead,
  TableHeader as TableHeaderPrimitive,
} from './table';
import { flexRender } from '@tanstack/react-table';

function TableHeader({ headerGroups }) {
  return (
    <TableHeaderPrimitive>
      {headerGroups.map((headerGroup) => (
        <TableRow key={headerGroup.id}>
          {headerGroup.headers.map((header) => {
            return (
              <TableHead
                key={header.id}
                className={header.column.columnDef.meta?.className ?? ''}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </TableHead>
            );
          })}
        </TableRow>
      ))}
    </TableHeaderPrimitive>
  );
}

export default TableHeader;
