// Dropdown.js
import React from 'react';
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from '@radix-ui/react-dropdown-menu';
import { ChevronDown } from 'lucide-react';

const Dropdown = ({ options, trigger, onChange }) => {
  const handleSelect = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption);
    }
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex border px-4 py-2.5 border-primary rounded-lg items-center">
        <div className="pr-2">{trigger}</div>
        <ChevronDown color="#514F4F" width={18} />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white shadow-md rounded-md p-2">
        {options.map((option) => (
          <React.Fragment key={option.value}>
            {option.isLabel && (
              <DropdownMenuLabel className="text-gray-700">
                {option.label}
              </DropdownMenuLabel>
            )}
            {option.isSeparator && <DropdownMenuSeparator className="my-1" />}
            {option.isItem && (
              <DropdownMenuItem
                className="cursor-pointer px-2 py-1"
                onClick={() => handleSelect(option.value)}
              >
                {option.label}
              </DropdownMenuItem>
            )}
          </React.Fragment>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default Dropdown;
