import React from 'react';

const EditIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 11.5278V10.1389H8.61113V11.5278H2.5ZM2.5 8.12499V6.7361H12.0834V8.12499H2.5ZM2.5 4.7222V3.33333H12.0834V4.7222H2.5ZM10 16.6667V14.1042L15.4792 8.64583L18.0278 11.1875L12.5625 16.6667H10ZM11.25 15.4167H12.0417L14.5625 12.875L14.1875 12.4792L13.7917 12.1042L11.25 14.625V15.4167ZM14.1875 12.4792L13.7917 12.1042L14.5625 12.875L14.1875 12.4792Z"
        fill={color}
      />
    </svg>
  );
};

export default EditIcon;
