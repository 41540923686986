import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../ui/Button';
import { Input } from '../../ui/Input';
import usernameIcon from '../../../assets/username.svg';
import passwordIcon from '../../../assets/password.svg';
import ErrorMessage from '../../ui/ErrorMessage';
import { Label } from '@/components/ui/label';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useAuth } from '../AuthProvider';

const LoginFormDrivers = ({ showPassword, setShowPassword }) => {
  const navigate = useNavigate();
  const { isAuthenticated, message, setMessage, login, logout } = useAuth();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!showPassword) {
      setShowPassword(true);
      return;
    }
    setMessage({ text: '', type: null });
    login(email, 'A' + password);
  };

  const baseURL = process.env.REACT_APP_ROOT_API;

  const navigateToDashboard = async (email) => {
    await axios
      .get(`${baseURL}/drivers`, {
        headers: {
          Authorization: `Bearer ${Cookies.get('accessToken')}`,
        },
      })
      .then((response) =>
        {
        navigate(
          `/dashboard-drivers?id=${
            response.data.drivers.filter((e) => e.email === email)[0]?._id
          }`
        )}
      );
  };

  useEffect(() => {
    logout(); 
  }, []);

  useEffect(() => {
    if (isAuthenticated && email) {
      navigateToDashboard(email);
    }
  }, [isAuthenticated]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-5" style={{ display: showPassword ? 'none' : '' }}>
        <Label htmlFor="username">Email</Label>
        <Input
          type="text"
          value={email}
          required
          icon={<img src={usernameIcon} alt="Email" />}
          onChange={(e) => setEmail(e.target.value)}
        />
      </div>
      <div className="mb-5" style={{ display: showPassword ? '' : 'none' }}>
        <Label htmlFor="password">Password</Label>
        <Input
          type="password"
          value={password}
          required
          icon={<img src={passwordIcon} alt="Password" />}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <Button className="w-full" type="button" onClick={handleSubmit}>
        {showPassword ? 'DONE' : 'LOGIN'}
      </Button>
      <div className="mt-5">
        {message.type === 'error' && <ErrorMessage message={message.text} />}
        {message.type === 'info' && <p>{message.text}</p>}
      </div>
    </form>
  );
};

export default LoginFormDrivers;
