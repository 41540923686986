import * as z from 'zod';

const formSchema = z
  .object({
    carrierCode: z.string(),
    carrierCompanyName: z.string(),
    contactName: z.string(),
    contactEmail: z.string(),
    contactNumber: z.string(),
    dotNumber: z.string(),
    driverId: z.string(),
    driverName: z.string().min(1, { message: "Driver's name is required" }),
    driverOrCarrierPercentage: z
      .string()
      .min(1, { message: "Driver's/Carrier's percentage is required" }),
    mainTruckNumber: z
      .string()
      .min(1, { message: 'Main truck number is required' }),
    truckNumber2: z.string(),
    truckNumber3: z.string(),
    mainTrailerType: z
      .string()
      .min(1, { message: 'Main trailer type is required' }),
    numberMainTrailerType: z.string().min(1, { message: 'Required' }),
    trailerType2: z.string(),
    numberTrailerType2: z.string(),
    trailerType3: z.string(),
    numberTrailerType3: z.string(),
    companyOrOwner: z.string().min(1, { message: 'Company/Owner is required' }),
    phoneNumber: z
      .string()
      //.regex(/[0-9]{10}/g, {
      //  message: 'Phone is required and should be 10 digits',
      //})
      //.max(10, { message: 'Phone is required and should be 10 digits' })
      .min(1, { message: 'Phone Number is required' })
      ,
    email: z.string(),
    state: z.string().min(1, { message: 'State percentage is required' }),
    city: z.string().min(1, { message: 'City is required' }),
    zipCode: z.string(),
    address: z.string(),
    _id: z.string(),
    creationDate: z.string(),
    birthDate: z.string().min(1),
  })
  .superRefine((values, ctx) => {
    if (values.carrierCode && !values.carrierCompanyName) {
      ctx.addIssue({
        message: 'Carrier company name is required',
        code: z.ZodIssueCode.custom,
        path: ['carrierCompanyName'],
      });
    }
    if (values.carrierCode && !values.contactName) {
      ctx.addIssue({
        message: 'Carrier contact name is required',
        code: z.ZodIssueCode.custom,
        path: ['contactName'],
      });
    }
    if (values.carrierCode && !values.contactEmail) {
      ctx.addIssue({
        message: 'Carrier contact email is required',
        code: z.ZodIssueCode.custom,
        path: ['contactEmail'],
      });
    }
    if (values.carrierCode && !values.contactNumber) {
      ctx.addIssue({
        message: 'Carrier contact number is required',
        code: z.ZodIssueCode.custom,
        path: ['contactNumber'],
      });
    }
    if (values.carrierCode && !values.dotNumber) {
      ctx.addIssue({
        message: 'Carrier DOT number is required',
        code: z.ZodIssueCode.custom,
        path: ['dotNumber'],
      });
    }
    if (!values.carrierCode && !values.driverId) {
      ctx.addIssue({
        message: "Driver's ID is required",
        code: z.ZodIssueCode.custom,
        path: ['driverId'],
      });
    }
    if (!values.carrierCode && !values.email) {
      ctx.addIssue({
        message: 'Email is required',
        code: z.ZodIssueCode.custom,
        path: ['email'],
      });
    }
    if (!values.carrierCode && !values.zipCode) {
      ctx.addIssue({
        message: 'Zip code is required',
        code: z.ZodIssueCode.custom,
        path: ['zipCode'],
      });
    }
    if (!values.carrierCode && !values.address) {
      ctx.addIssue({
        message: 'Address is required',
        code: z.ZodIssueCode.custom,
        path: ['address'],
      });
    }
  });

export default formSchema;
