import React from 'react';
import Header from '../../components/Header';
import { USER_TABS } from '../../lib/constants';
import UserSettingsTabs from '@/components/userSettings/UserSettingsTabs';

const UserSettings = () => {
  return (
    <>
      <Header title="User Settings" />
      <UserSettingsTabs tabs={USER_TABS} />
    </>
  );
};

export default UserSettings;