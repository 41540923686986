import { useState, useEffect } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';

const useApi = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [statusCode, setStatusCode] = useState(null);
  const [loading, setLoading] = useState(false);

  // Configure your base URL
  const baseURL = process.env.REACT_APP_ROOT_API; // Replace with your base URL

  // Create an instance of axios with common configuration
  const api = axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
      // Add additional headers as needed
      Authorization: `Bearer ${Cookies.get('accessToken')}`,
    },
  });

  api.interceptors.request.use(
    (config) => {
      // Add your variable to the request config
      config.params = {
        ...config.params,
        agentId: Cookies.get('agentId') || '',
      };
      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (
        Cookies.get('refreshToken') &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const tokens = await axios.post(
          `${baseURL}/auth/refresh-token`,
          {
            refreshToken: `${Cookies.get('refreshToken')}`,
          },
          {
            headers: {
              Authorization: `Bearer ${Cookies.get('accessToken')}`,
            },
          }
        );
        Cookies.set('accessToken', tokens.data.accessToken);
        originalRequest.headers.Authorization =
          'Bearer ' + Cookies.get('accessToken');
        return axios(originalRequest);
      }
      return Promise.reject(error);
    }
  );

  const makeApiCall = async (config) => {
    setLoading(true);

    try {
      const response = await api(config);
      setData(response.data);
      setStatusCode(response.status);
      setError(null);
      return response;
    } catch (error) {
      setError(error.message || 'Something went wrong');
      return error.response;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // You can perform any setup or cleanup here
    // For example, setting up default headers, interceptors, etc.
    // api.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken();
    // return () => {
    //   cleanup logic here
    // };
  }, []);

  return { data, error, statusCode, loading, makeApiCall };
};

export default useApi;
