import { useRef } from 'react';
import axios from 'axios';
import { useFieldArray } from 'react-hook-form';

import useApi from '@/hooks/useApi';
import { generateS3FileKey } from '@/lib/utils';
import { useAppContext } from '@/contexts/AppContext';

const useFileUploadLogic = (control) => {
  const { currentForm } = useAppContext();
  const fileInputRef = useRef(null);
  const { makeApiCall } = useApi();

  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'files', // unique name for your Field Array
  });

  // Handle the upload of a single file
  const uploadSingleFile = async (file, presignedPUTURL) => {
    try {
      const response = await axios.put(presignedPUTURL, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      if (response.status === 200) {
        // Update the state with the uploaded file's details
        append({
          name: file.name,
          path: file.path,
        });
      } else {
        console.error('Error uploading file:', response);
      }
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  // Upload multiple files, generating S3 file keys and handling API calls
  const uploadMultipleFiles = async (newFiles) => {
    newFiles.forEach(async (file) => {
      const path = generateS3FileKey(file?.name);
      const response = await makeApiCall({
        url: '/files',
        method: 'POST',
        data: { path },
      });
      file.path = path;
      if (response.status === 200) {
        uploadSingleFile(file, response.data.presignedPUTURL);
      }
    });
  };

  const files = currentForm?.files ?? (fields ? fields : []);

  return {
    fileInputRef,
    files,
    control,
    remove,
    uploadMultipleFiles,
    // Export other functions as needed
  };
};

export default useFileUploadLogic;
