const Header = ({ children, showRequired = false }) => (
  <div className="flex justify-between">
    <h1 className="text-xs font-semibold tracking-widest">{children}</h1>
    {showRequired && (
      <p className="text-sm font-bold text-gray-900">*Required fields</p>
    )}
  </div>
);

export default Header;
