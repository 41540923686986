import React from 'react';

const SearchIcon = ({ color }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17 17.5L11.5972 12.0972C11.1806 12.4491 10.697 12.7245 10.1464 12.9236C9.59585 13.1227 9.01002 13.2222 8.3889 13.2222C6.88277 13.2222 5.60809 12.7002 4.56485 11.6562C3.52162 10.6123 3 9.34724 3 7.86112C3 6.37501 3.52199 5.10997 4.56598 4.06598C5.60995 3.02199 6.87731 2.5 8.36806 2.5C9.8588 2.5 11.1238 3.02199 12.1632 4.06598C13.2025 5.10997 13.7222 6.37571 13.7222 7.86321C13.7222 8.46367 13.625 9.03936 13.4306 9.59029C13.2361 10.1412 12.9537 10.6482 12.5833 11.1111L18 16.5L17 17.5ZM8.375 11.8334C9.47454 11.8334 10.4091 11.4456 11.1788 10.6701C11.9485 9.89469 12.3333 8.95835 12.3333 7.86112C12.3333 6.76389 11.9485 5.82754 11.1788 5.05208C10.4091 4.27663 9.47454 3.8889 8.375 3.8889C7.26775 3.8889 6.32658 4.27663 5.5515 5.05208C4.77642 5.82754 4.38888 6.76389 4.38888 7.86112C4.38888 8.95835 4.77642 9.89469 5.5515 10.6701C6.32658 11.4456 7.26775 11.8334 8.375 11.8334Z"
        fill={color}
      />
    </svg>
  );
};

export default SearchIcon;
