import React from 'react';
import LoginLayout from './Login/LoginLayout';
import LoginForm from './Login/LoginForm';

const Login = () => {
  return (
    <LoginLayout
      title={<h1 className='text-5xl font-bold leading-relaxed'>Welcome!</h1>}
      description='Please enter your username and password'
    >
      <LoginForm />
    </LoginLayout>
  );
};

export default Login;
