import { cn } from '@/lib/utils';
import { NavLink } from 'react-router-dom';

export const MenuItem = ({ link, text, icon: Icon, isSubMenuItem }) => {
  return (
    <NavLink to={link}>
      {({ isActive }) => (
        <div
          className={cn(
            'flex items-center mb-1 py-2 px-4 rounded-md',
            isActive ? 'bg-primary' : '',
            isActive && isSubMenuItem && 'bg-[#CCDEEE]'
          )}
        >
          {Icon && <Icon width="18" color={isActive ? '#fff' : '#545454'} />}
          <span
            className={cn(
              'text-sm ml-2.5',
              isActive ? 'text-white' : 'text-gray',
              isActive && isSubMenuItem && 'text-primary'
            )}
          >
            {text}
          </span>
        </div>
      )}
    </NavLink>
  );
};
