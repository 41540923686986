export const headerMapping = {
  proNumber: 'ProNumber',
  division: 'Division',
  pickupDate: 'Pick-Up Date',
  shipperNumber: 'Shipper Number',
  consigneeNumber: 'Consignee Number',
  billToNumber: 'Bill To Number',
  jobName: 'Job Name',
  poNumber: 'PO #',
  ticketNumber: 'Ticket #',
  quantityUnit: 'Unit',
  rate: 'Rate',
  quantity: 'Quantity',
  fscPercentage: 'FSC %',
  fscTotal: 'FSC Total',
  waitTimeTotal: 'Wait Time Total',
  otherAccTotal: 'Other Acc. Total',
  haulTotal: 'Haul Total',
  mainTruckNumber: 'Truck #',
  truckTotalNoAcc: 'Truck Total NO ACC.',
  fscTruckTotal: 'FSC Truck %',
  waitTimeTruckTotal: 'Wait Time Truck Total',
  otherAccTruckTotal: 'Other Acc. Truck Total',
  truckTotal: 'Truck Total',
  commissionAgent1: 'Commission Agent 1',
  agentPercentage1: '% Agent 1',
  commissionAgent2: 'Commission Agent 2',
  agentPercentage2: '% Agent 2',
  sequence: 'Sequence',
  proNumberRepetitions: 'Tickets per ProNumber',
};
