import React from 'react';
import { Label } from './label';
import { FormFieldInput } from './form';
import UploadedFiles from './UploadedFiles';
import useFileUploadLogic from '@/hooks/useFileUploadLogic';

const FileUploadTicket = ({ control, isViewMode }) => {
  const { fileInputRef, files, remove, uploadMultipleFiles } =
    useFileUploadLogic(control);

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files);
    uploadMultipleFiles(newFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <div className="w-full">
      {!isViewMode && (
        <>
          <Label>Files*</Label>
          <div
            className="flex items-center flex-col text-gray-500 cursor-pointer"
            onClick={handleButtonClick}
          >
            <p className="my-2 text-white px-[15px] py-[10px] bg-[#015caa] rounded-[10px]">
              <span>Click to add your ticket file</span>
            </p>
          </div>
          <input
            type="file"
            className="hidden"
            ref={fileInputRef}
            onChange={handleFileChange}
            multiple
          />
        </>
      )}
      <UploadedFiles files={files} remove={remove} isViewMode={isViewMode} />
      <FormFieldInput
        disabled={isViewMode}
        label="Ticket Number"
        name="ticketNumber"
        placeholder="000000"
        control={control}
      />
    </div>
  );
};

export default FileUploadTicket;
