import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../Logo';
import tireVector from '../../../assets/tire.svg';
import loginVector from '../../../assets/login.png';

const LoginLayoutDrivers = ({ children, title, description }) => {
  return (
    <main>
      <div className="grid grid-cols-12 gap-2 min-h-screen overflow-x-hidden">
        <div className="col-span-12 md:col-span-5 relative py-12">
          <div className="flex flex-col items-center px-8">
            <Link to="/" className="w-full flex justify-center">
              <Logo
                color="#013D71"
                className="z-10 mb-10 w-full max-w-[375px]"
              />
            </Link>
            <img
              src={tireVector}
              alt="Tire Vector"
              className="absolute top-0 right-[-25%] z-[-1]"
              width={370}
            />
            <div className="w-full max-w-[362px]">
              <div className="text-center mb-10">
                {title}
                <p className="text-lg">{description}</p>
              </div>
              {children}
            </div>
          </div>
          <div className="bg-gradient-to-b from-transparent to-[#CCDEEE] absolute top-0 w-full h-full z-[-1] block md:hidden"></div>
        </div>
        <div className="col-span-7 bg-gradient-to-r from-transparent to-[#CCDEEE] hidden md:flex items-center">
          <img
            src={loginVector}
            alt="User authentication process vector"
            className="max-h-screen z-10 relative"
          />
        </div>
      </div>
    </main>
  );
};

export default LoginLayoutDrivers;
