import React from 'react';

const CopyIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.44446 16.3889V0.833328H12.6111L17.5 5.72222V16.3889H4.44446ZM11.9167 6.3611V2.2222H5.83333V15H16.1111V6.3611H11.9167ZM1.66669 19.1667V5.29164H3.05556V17.7778H13.0278V19.1667H1.66669Z"
        fill={color}
      />
    </svg>
  );
};

export default CopyIcon;
