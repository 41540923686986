import React from 'react';
import Header from '@/components/Header';
import useApi from '@/hooks/useApi';
import { useNavigate } from 'react-router-dom';

const Agents = () => {
  const { makeApiCall } = useApi();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    await makeApiCall({
      url: '/agents',
      method: 'POST',
      data: {
        email: e.target.email.value,
        agentId: e.target.id.value,
      },
    })
      .then(() => navigate('/orders'))
      .catch((e) => {
        alert('Error adding agent to table');
      });
  };

  return (
    <>
      <Header title="Agents" primaryAction={{}} />
      <form
        onSubmit={(e) => handleSubmit(e)}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <div style={{ display: 'flex', gap: '10px' }}>
          <label htmlFor="email">Agent Email</label>
          <input
            type="text"
            name="email"
            id="email"
            style={{ border: '1px solid black', fontSize: '14px' }}
          />
        </div>
        <div style={{ display: 'flex', gap: '10px' }}>
          <label htmlFor="id">Agent ID</label>
          <input
            type="text"
            name="id"
            id="id"
            style={{ border: '1px solid black', fontSize: '14px' }}
          />
        </div>
        <div>
          <button
            type="submit"
            style={{
              backgroundColor: '#eeeeee',
              padding: '5px 10px',
              border: '1px solid black',
            }}
          >
            Add
          </button>
        </div>
      </form>
    </>
  );
};

export { Agents };
