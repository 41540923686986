import React, { useState } from 'react';
import LoginLayoutDrivers from './LoginDrivers/LoginLayoutDrivers';
import LoginFormDrivers from './LoginDrivers/LoginFormDrivers';

const LoginDrivers = () => {
  
  const [showPassword, setShowPassword] = useState(false);

  return (
    <LoginLayoutDrivers
      title={<h1 className='text-5xl font-bold leading-relaxed'>{showPassword ? 'Verification' : 'Welcome!'}</h1>}
      description={showPassword ? 'Please enter your password' : 'Please enter your email'}
    >
      <LoginFormDrivers showPassword={showPassword} setShowPassword={setShowPassword}/>
    </LoginLayoutDrivers>
  );
};

export default LoginDrivers;
