import { ChevronDown } from 'lucide-react';
import React, { useState } from 'react';
import { MenuItem } from './MenuItem';
import { cn } from '@/lib/utils';
import { useLocation, matchPath } from 'react-router-dom';

const SubMenuNavItem = ({ text, icon: Icon, subMenuItems }) => {
  const { pathname } = useLocation();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const isSettingsPath = matchPath('/settings/*', pathname);

  const toggleSubMenu = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  return (
    <li className="relative">
      <div
        onClick={toggleSubMenu}
        className={cn(
          isSettingsPath && 'bg-primary',
          'flex items-center mb-1 py-2 px-4 rounded-md cursor-pointer'
        )}
      >
        <div className="flex items-center">
          {Icon && (
            <Icon width="18" color={isSettingsPath ? '#fff' : '#545454'} />
          )}
          <span
            className={cn(
              'text-sm ml-2.5',
              isSettingsPath ? 'text-white' : 'text-gray'
            )}
          >
            {text}
          </span>
        </div>
        <ChevronDown
          width={18}
          color={isSettingsPath ? '#fff' : '#545454'}
          className={`ml-3 transform ${isSubMenuOpen ? 'rotate-180' : ''}`}
        />
      </div>

      {/* Sub-menu */}
      {isSubMenuOpen && (
        <ul className="ml-4 mt-2">
          {subMenuItems.map((subMenuItem, index) => (
            <MenuItem
              key={index}
              text={subMenuItem.text}
              link={subMenuItem.link}
              isSubMenuItem
            />
          ))}
        </ul>
      )}
    </li>
  );
};

export default SubMenuNavItem;
